import {
    createDocumentClassificationFromDto,
    CrsUpdatedDocsDocumentDTO,
    CrsUpdatedDocsStatus,
    NewAndChangedDocument,
    NewAndChangedDocumentStatus,
} from '@idr/shared/model';

export const createNewAndChangedDocument = (
    updatedDocsDocumentDTO: CrsUpdatedDocsDocumentDTO,
): NewAndChangedDocument | undefined => {
    if (!updatedDocsDocumentDTO) {
        return undefined;
    }

    // We need to translate the CRS status to our own status
    const crsStatusMap: Record<CrsUpdatedDocsStatus, NewAndChangedDocumentStatus> = {
        [CrsUpdatedDocsStatus.ADDED]: NewAndChangedDocumentStatus.NEW,
        [CrsUpdatedDocsStatus.MODIFIED]: NewAndChangedDocumentStatus.UPDATED,
    };

    return {
        id: updatedDocsDocumentDTO.docid,
        type: createDocumentClassificationFromDto(updatedDocsDocumentDTO.docclass).description,
        date: updatedDocsDocumentDTO.docstate.date ?? '',
        title: updatedDocsDocumentDTO.longTitle,
        updateMessage: updatedDocsDocumentDTO.updateInfo?.message,
        status: crsStatusMap[updatedDocsDocumentDTO.updateInfo?.status],
    };
};
