import { Route, Routes } from '@angular/router';
import { logger } from '@idr/shared/utils';
import { DEEPLINK_ROUTES } from '../core/deeplinks/deeplink.routes';
import { delegateStateToProductResolverGuard } from '../core/deeplinks/delegate-state-to-product-resolver.guard';
import { ProductResolver } from '../core/product/product-resolver.service';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { RoutePaths } from './route-paths';

const ERROR_ROUTE: Route = {
    path: RoutePaths.Error,
    loadChildren: () => import('../error-page/error-page.routes'),
};

const UNKNOWN_ROUTES: Route = {
    path: '**', // undefined routes or in other words: 404
    redirectTo: RoutePaths.Error,
};

// this one works without a product-id context
const CONFIG_ROUTE: Route = {
    path: RoutePaths.Config,

    loadChildren: () => import('@idr/ui/config').then(uiConfigLib => uiConfigLib.ROUTING),
};

const LAZY_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../start-page/start-page.routes'),
    },
    {
        path: RoutePaths.Search,
        loadChildren: () => import('../search-page/search.routes'),
    },
    {
        path: RoutePaths.Document,
        loadChildren: () => import('../document/document.routes'),
    },
    {
        path: RoutePaths.Imprint,
        redirectTo: 'document/imprint',
    },
    {
        path: RoutePaths.Lexicon,
        loadChildren: () => import('../alphabetical-index-page/alphabetical-index.routes'),
    },
    {
        path: RoutePaths.Column,
        loadChildren: () => import('../bauplan-page/bauplan.routes'),
    },
    {
        path: RoutePaths.Filter,
        loadChildren: () => import('../filter-table-page/filter-table.routes'),
    },
    {
        path: RoutePaths.TopicPage,
        loadChildren: () => import('../topic-page/topic-page.routes'),
    },
    {
        path: RoutePaths.NewAndChanged,
        loadChildren: () => import('../new-and-changed-page/new-and-changed.routes'),
    },
    {
        path: RoutePaths.DevDocumentation,
        loadChildren: () => import('../documentation/documentation.routes'),
    },
    {
        path: RoutePaths.News,
        loadChildren: () => import('../news-page/news.routes'),
    },
    {
        path: RoutePaths.Hot,
        loadChildren: () => import('../hot-page/hot.routes'),
    },
];

export const routes: Routes = [
    {
        matcher: segments => {
            // FIXME NAUA-7127, NAUA-7130, NAUA-7132, NAUA-7154, NAUA-7186
            //       we can also use a way simpler pattern of e.g. /[HLK]I\d+/ since it doesn't really matter what kind of documentId it is
            //       the following logic needs to request it & the request is the same for all different kinds
            // see https://regex101.com/r/gZEYct/2
            // see https://regex101.com/r/gZEYct/3
            if (segments?.length > 1 && segments[1].path.match(/[HKL]I\d+/)) {
                logger.debug('[AppRoutingModule] DOCUMENT deeplink with ->', segments[1].path);
                // determine which kind of document is meant by this... maybe it shall be a jump to an anchor...
                return { consumed: segments };
            }

            return null;
        },
        redirectTo: '',
    },
    {
        path: '',
        pathMatch: 'full',
        children: [],
        resolve: {
            product: ProductResolver,
        },
    },
    CONFIG_ROUTE,
    // !order matters here... don't move "error" route after ":productId" route!
    // would result into bugs like endless loop when trying to access not licensed or unknown product
    ERROR_ROUTE,
    {
        path: ':productId',
        component: AppLayoutComponent,
        resolve: {
            product: ProductResolver,
        },
        canActivate: [delegateStateToProductResolverGuard],
        children: [...DEEPLINK_ROUTES, ...LAZY_ROUTES],
    },
    UNKNOWN_ROUTES,
];
