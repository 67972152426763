import { Injectable } from '@angular/core';
import { EcondaConversionValue, EcondaTarget, EcondaTargetGroup, EcondaTargetName } from '@idr/shared/model';
import { EcondaTrackingService } from '../../../core/tracking';

const CONTEXT: EcondaTargetGroup = EcondaTargetGroup.Document;

interface DownloadTrackingParams {
    readonly extension: string;
    readonly file: string;
}

/**
 * @returns a filled DownloadTrackingParams object if given link matches criteria, `undefined` if not
 */
const extractDownloadTrackingParams = (link: HTMLAnchorElement): DownloadTrackingParams | undefined => {
    const href: string = link.href;
    // eslint-disable-next-line no-useless-escape
    const matchResult: RegExpExecArray = /([H|L]I[^\.]+)\.(\w+)/g.exec(href);
    if (!matchResult || matchResult.length !== 3) {
        return undefined;
    }
    return {
        file: matchResult[1],
        extension: matchResult[2],
    };
};

/**
 * Holds document module specific tracking logic to simplify the calls in the components...
 */
@Injectable({ providedIn: 'root' })
export class DocumentTrackingService {
    constructor(private readonly econdaTrackingService: EcondaTrackingService) {}

    public trackDocSearch(query: string): void {
        const target: EcondaTarget = new EcondaTarget(CONTEXT, `${EcondaTargetName.SearchTriggered}/${query}`);
        this.econdaTrackingService.trackTarget(target);
    }

    public trackDownload(link: HTMLAnchorElement): void {
        const params: DownloadTrackingParams = extractDownloadTrackingParams(link);
        if (!params) {
            return;
        }
        const targetName = `Download/${params.extension.toUpperCase()}/${params.file}`;
        const trackingTarget: EcondaTarget = new EcondaTarget(CONTEXT, targetName);
        this.econdaTrackingService.trackTarget(trackingTarget);
    }

    public trackTocToggle(opened: boolean, level: number): void {
        const conversionValue: EcondaConversionValue = opened
            ? EcondaConversionValue.POSITIVE
            : EcondaConversionValue.NEGATIVE;
        const target: EcondaTarget = new EcondaTarget(
            EcondaTargetGroup.Document,
            `Gliederung/Ebene/${level}`,
            conversionValue,
        );
        this.econdaTrackingService.trackTarget(target);
    }
}
