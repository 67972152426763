import { DocumentId } from './document-id';

export const NewAndChangedDocumentStatus = {
    NEW: 'new',
    UPDATED: 'updated',
} as const;

export type NewAndChangedDocumentStatus =
    (typeof NewAndChangedDocumentStatus)[keyof typeof NewAndChangedDocumentStatus];

export interface NewAndChangedDocument {
    readonly id: DocumentId;
    readonly type: string;
    readonly date: string;
    readonly title: string;
    readonly updateMessage: string;
    readonly status: NewAndChangedDocumentStatus;
}
