import { DocumentId } from '../document/document-id';
import { ProductId } from '../product/product-id';
import { CrsDocClassDTO, CrsDocStateDTO } from './crs-document';
import { CrsUpdatedDocsStatus, CrsUpdatedDocsUpdateInfoDTO } from './crs-updated-docs-update-info';

export interface CrsUpdatedDocumentsDTO {
    readonly numdocs: number;
    readonly product: CrsUpdatedDocsProductDTO[];
}

export interface CrsUpdatedDocsProductDTO {
    readonly productname: string;
    readonly productid: ProductId;
    readonly path?: string;
    readonly numdocs?: number;
    readonly timeframeInDays?: number;
    readonly document?: CrsUpdatedDocsDocumentDTO[];
    readonly cluster?: CrsUpdatedDocsClusterDTO[];
}

export interface CrsUpdatedDocsDocumentDTO {
    readonly docid: DocumentId;
    readonly docclass: CrsDocClassDTO;
    readonly docstate: CrsDocStateDTO;
    readonly longTitle: string;
    readonly updateInfo: CrsUpdatedDocsUpdateInfoDTO;
}

export const getCrsUpdatedDocsStatusFromString = (status: string): CrsUpdatedDocsStatus | undefined => {
    switch (status?.toUpperCase()) {
        case 'ADDED':
            return CrsUpdatedDocsStatus.ADDED;
        case 'MODIFIED':
            return CrsUpdatedDocsStatus.MODIFIED;
    }
    return undefined;
};

export const CrsUpdatedDocsSort = {
    SYSTEM: 'system',
    CATEGORY_ASCENDING: 'gattung2+',
    CATEGORY_DESCENDING: 'gattung2-',
    TITLE_ASCENDING: 'titel+',
    TITLE_DESCENDING: 'titel-',
    STATE_ASCENDING: 'stand+',
    STATE_DESCENDING: 'stand-',
};

export type CrsUpdatedDocsSort = (typeof CrsUpdatedDocsSort)[keyof typeof CrsUpdatedDocsSort];

export const getCrsUpdatedDocsSortFromString = (sort: string): CrsUpdatedDocsSort | undefined => {
    if (!sort) {
        return undefined;
    } else {
        switch (sort) {
            case 'stand':
                return CrsUpdatedDocsSort.STATE_ASCENDING;
            case 'stand-':
                return CrsUpdatedDocsSort.STATE_DESCENDING;
            case 'titel':
                return CrsUpdatedDocsSort.TITLE_ASCENDING;
            case 'titel-':
                return CrsUpdatedDocsSort.TITLE_DESCENDING;
            case 'gattung2':
                return CrsUpdatedDocsSort.CATEGORY_ASCENDING;
            case 'gattung2-':
                return CrsUpdatedDocsSort.CATEGORY_DESCENDING;
            default:
                return undefined;
        }
    }
};

export interface CrsUpdatedDocsClusterDTO {
    readonly path: string;
    readonly name: string;
    readonly numdocs?: number;
    readonly document?: CrsUpdatedDocsDocumentDTO[];
    readonly cluster?: CrsUpdatedDocsClusterDTO[];
}
