import { DocumentId } from '@idr/shared/model';
import { DocumentSearchResult } from '@idr/ui/shared';

/**
 * It is either a {@link DocumentId} or a combined version of {@link DocumentId} and used query for requesting the chapter.
 */
export type ChapterIndexKey = DocumentId | string;

export const getChapterIndexKey = (id: DocumentId, search?: DocumentSearchResult): ChapterIndexKey => {
    const useQuery = search?.resultsFound && search?.docIds?.includes(id);
    return useQuery ? `${id}:${useQuery}` : id;
};
