import { Router } from '@angular/router';
import { ProductService } from '@idr/ui/shared';
import { filter, tap } from 'rxjs';
import { RoutePaths } from '../../app/app/route-paths';

export const showErrorPageForNoProducts = (router: Router, service: ProductService) => () =>
    service.products$
        .pipe(
            filter(products => products?.length === 0),
            // Users without any product licensed should see a proper error page...
            tap(() => router.navigate(['/', RoutePaths.Error, RoutePaths.LicenseError])),
        )
        .subscribe();
