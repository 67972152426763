import { logger } from '@idr/shared/utils';
import { RxState } from '@rx-angular/state/lib/rx-state';
import { distinctUntilChangedForPending } from '../distinct-until-changed-for-pending';
import { DocumentState, RestoreFocus } from '../types';
import { Observable } from 'rxjs';

export const addDocumentModelDebugLogging = (
    state: RxState<DocumentState>,
    // Unfortunately rx-angular doesn't export RxEffects type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    effects: any,
    logPrefix: string,
    focusedAnchorId$: Observable<string>,
    restoreFocus$: Observable<RestoreFocus>,
) => {
    effects.register(state.select('focus'), _state => {
        logger.debug(logPrefix, 'state.focus ->', _state);
    });

    effects.register(focusedAnchorId$, _state => {
        logger.debug(logPrefix, 'focusedAnchorId$ ->', _state);
    });

    effects.register(state.select('pending').pipe(distinctUntilChangedForPending()), _state => {
        logger.debug(
            logPrefix,
            'pending$ -> documents:',
            _state.documents?.toJSON(),
            'waitingForFocusRestoration:',
            _state.waitingForFocusRestoration,
        );
    });

    effects.register(state.select('query'), _state => {
        logger.debug(logPrefix, 'query$ ->', _state);
    });

    effects.register(restoreFocus$, _state => {
        logger.debug(logPrefix, 'restoreFocus$ ->', _state);
    });

    effects.register(state.select('searchCache'), _state => {
        logger.debug(logPrefix, 'state.searchCache ->', _state.toJSON());
    });

    effects.register(state.select('chapterIndex'), _state => {
        logger.debug(logPrefix, 'state.chapterIndex ->', _state.toJSON());
    });
};
