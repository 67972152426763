import { DocumentId } from '@idr/shared/model';
import {
    containsHot,
    containsNews,
    containsTopicPage,
    documentIdFromUrl,
    isSearchPage,
    logger,
} from '@idr/shared/utils';
import { GtmEventBase } from './gtm-event';

export interface GtmCurrentDocumentData {
    readonly id?: DocumentId;
    readonly title?: string;
    readonly docDisplayType?: string;
    readonly docType?: string;
    readonly docSubType?: string;
    readonly rootId?: string;
    readonly rootTitle?: string;
}

export interface GtmPageChangeEvent extends GtmEventBase {
    readonly event: 'PageChange';
    readonly url: string;
    readonly currentDocument?: GtmCurrentDocumentData;
}

/**
 * returns true when it is searchPage and contains one of: document, topicPage, hot, news
 * if given url is not searchpage return true as well
 */
export const isAllowedToBeTracked = (event: GtmPageChangeEvent): boolean => {
    if (isSearchPage(event.url)) {
        if (!event.currentDocument) {
            return false;
        }
        const containsDocumentConditions = [
            documentIdFromUrl(event.url) !== undefined,
            containsTopicPage(event.url),
            containsHot(event.url),
            containsNews(event.url),
        ];
        // when one condition is met consider valid
        const oneIsValid = containsDocumentConditions.some(v => v);
        if (!oneIsValid) {
            logger.warn(`GtmPageChangeEvent -> skipped (${oneIsValid})`, ...containsDocumentConditions, event);
        }
        return oneIsValid;
    }
    return true;
};
