const getEstimatedLines = (fontSize: number, docSize: number, width: number): number => {
    // rough estimate; we only take a portion the fontSize because that only tells something about height of a character;
    //                 the characters are higher than wide...
    const charsPerLine = Math.ceil(width / (fontSize * 0.66));
    return Math.ceil(docSize / charsPerLine);
};

/**
 * Calculates the estimated height for the given {@param docSize} and {@param context}.
 */
export const convertDocSizeToHeightEmString = (
    docSize: number,
    context: {
        readonly fontSize: number;
        readonly width: number;
    },
): string => {
    if (docSize === 0) {
        return '0';
    }
    const lines = getEstimatedLines(context.fontSize, docSize, context.width);
    return `${lines}em`;
};
