import { Location } from '@angular/common';
import { EnvironmentInjector, inject, Inject, Injectable } from '@angular/core';
import { ActivatedRoute, EventType, NavigationEnd, Router } from '@angular/router';
import { DebugSettings, DocumentId } from '@idr/shared/model';
import { documentIdFromUrl, logger } from '@idr/shared/utils';
import { DocumentMeta, DocumentService, IDeskDocument, RoutedDocument, Toc, TocEntry } from '@idr/ui/document';
import { DEBUG_SETTINGS, DocumentSearchResult, TextSelection } from '@idr/ui/shared';
import { update } from '@rx-angular/cdk/transformations';
import { rxState } from '@rx-angular/state';
import { rxActions } from '@rx-angular/state/actions';
import { rxEffects } from '@rx-angular/state/effects';
import { ProjectStateReducer } from '@rx-angular/state/lib/rx-state.service';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { combineLatest, merge, MonoTypeOperatorFunction, noop, Observable, of, OperatorFunction } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    shareReplay,
    skip,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { createIDeskDocumentDummy } from '../../../core/document/utils';
import { immutableDistinctUntilChanged } from '../../../utils/rxjs';
import { DOCUMENT_SERVICE } from '../../tokens';
import { GET_FONT_SIZE, GetFontSizeFn } from '../../utils/get-font-size';
import { addDocumentModelDebugLogging } from '../debug/add-document-model-debug-logging';
import {
    Chapter,
    ChaptersUpdate,
    DocumentState,
    FocusChapter,
    getChapterIndexKey,
    QueryInDocument,
    RestoreFocus,
    UI,
} from '../types';
import { Pending } from '../types/document-state';
import { DocumentModelActions } from './document-model-actions';

interface InitialChapters {
    readonly chapters: ImmutableList<Chapter>;
    readonly chapterIndex: ImmutableMap<DocumentId, Chapter>;
}

const DEBOUNCE_TIME_FOR_PENDING = 100;

const MAX_CHAPTER_REQUEST_AT_ONCE = 5;

const INITIAL_STATE: Partial<DocumentState> = {
    backendError: false,
    chapterIndex: ImmutableMap(),
    chapters: ImmutableList(),
    initialLoading: true,
    pending: {
        documents: ImmutableSet(),
        waitingForFocusRestoration: false,
    },
    requestQueue: ImmutableSet(),
    searchCache: ImmutableMap(),
};

@Injectable()
export class DocumentModel {
    readonly actions = rxActions<DocumentModelActions>(({ transforms }) => {
        transforms({
            computeFocus: () => {
                logger.debug(this.#logPrefix, 'computeFocus');
            },
            restoreFocus: (focus: RestoreFocus) => {
                const pendingFocus = focus !== undefined;
                logger.debug(this.#logPrefix, 'restoreFocus ->', focus, 'setting `pendingFocus` to', pendingFocus);
                this.state.set('pending', current => ({
                    ...current.pending,
                    focus,
                    waitingForFocusRestoration: true,
                }));
                return focus;
            },
        });
    });

    // needs to be implemented first since the other observables depend on it
    readonly state = rxState<DocumentState>(({ set }) => set(INITIAL_STATE));

    /**
     * Will contain all rendered chapters of current document.
     * If the chapter is loaded completely it is a {@link IDeskDocument}.
     * And, before that (the default) you get only a {@link IDeskDocumentDummy}.
     */
    readonly chapters$: Observable<ImmutableList<Chapter>> = this.state.select('chapters');

    /**
     * Debounced version of {@link focusedChapter$}.
     * It delays notification on {@link focusedChapter$} by a small amount of time.
     *
     * In some cases, e.g. consider fast scrolling, you don't want to react on focus change right away.
     * This especially accounts to related requests for data like breadcrumb or related documents.
     *
     * If you are only interested in a focused {@link IDeskDocument} you should use {@link debouncedFocusedRealChapter$} instead.
     */
    readonly debouncedFocusedChapter$: Observable<Chapter> = this.#initDebouncedFocusedChapter$;

    /**
     * Derived from {@link debouncedFocusedChapter$} but only emits when focused chapter is a real one.
     */
    readonly debouncedFocusedRealChapter$: Observable<IDeskDocument> = this.#initDebouncedFocusedRealChapter$;

    readonly error$: Observable<boolean> = this.state.select('backendError');

    readonly focusedChapter$: Observable<Chapter> = this.state.select('focus', 'chapter');

    readonly focusedAnchorId$: Observable<string> = this.#initFocusedAnchorId$;

    /**
     * Always triggers after a once requested {@link RestoreFocus} got triggered and realised by UI.
     * @see restoreFocus$
     * @see focusRestored
     */
    readonly focusedRestored$: Observable<void> = this.#initFocusedRestored$;

    readonly initialLoading$: Observable<boolean> = this.state.select('initialLoading');

    readonly meta$: Observable<DocumentMeta> = this.state
        .select('meta')
        .pipe
        /**
         * I added distinct based on root here because it might be unexpected by consumers that you also get a meta change
         * when "only" focused chapter changed...
         * This is new since lately because we now rely on {@link DocumentMeta.title} and that should be kept in sync of course with the actual view state.
         */
        // distinctUntilChanged((previous, current) => previous.root.id !== current.root.id),
        ();

    readonly rendered$: Observable<QueryInDocument> = this.#initRendered$;

    /**
     * Triggers when model requests to restore focus to a specific chapter or specific part inside a chapter.
     */
    readonly restoreFocus$: Observable<RestoreFocus> = this.#initRestoreFocus$;

    /**
     * Triggers always when a complete new document got opened & loaded.
     */
    readonly documentChange$: Observable<void> = this.meta$.pipe(
        map(meta => meta.root.id),
        distinctUntilChanged(),
        map(() => void 0),
    );

    /**
     * Access to our routed document; when it is triggered model gets basically reloaded.
     */
    readonly routedDocument$: Observable<RoutedDocument> = this.state.select('routed');

    /**
     * Will emit when ever a document related route change occurs.
     * It emits even for same routes (e.g. when user clicks on same entry in table-of-contents over and over again).
     * It emits after the model has its state updated accordingly (if needed).
     */
    readonly routeChange$: Observable<RoutedDocument> = this.#initRouteChange$;

    readonly toc$: Observable<Toc> = this.#initToc$;

    readonly uiWidth$: Observable<number> = this.state.select('ui', 'width');

    #textSelection: TextSelection | undefined;

    readonly #logPrefix = '[DocumentModel]';

    readonly #query$: Observable<string> = this.state.select('query');

    readonly #routeData$ = inject(ActivatedRoute).data;

    readonly #routeParams$ = inject(ActivatedRoute).params;

    constructor(
        @Inject(DEBUG_SETTINGS) private readonly debug: DebugSettings,
        @Inject(GET_FONT_SIZE) getDefaultFontSize: GetFontSizeFn,
        @Inject(DOCUMENT_SERVICE) private readonly service: DocumentService,
        private readonly injector: EnvironmentInjector,
        private readonly location: Location,
    ) {
        logger.debug(this.#logPrefix, 'created', debug);
        this.fontSize = getDefaultFontSize();

        // ensure a clean empty model state when a complete different model gets visited
        // the state will be inconsistent at this point of time, but important is the loading state
        // down below model will be updated with consistent state (chapters, indexes, focus, ...)
        const documentChange$ = this.#routeData$.pipe(
            map(data => data?.document as RoutedDocument),
            filter(Boolean),
            distinctUntilChanged((previous, current) => previous.meta.root.id === current.meta.root.id),
            tap(() => logger.debug(this.#logPrefix, 'documentChange$')),
        );
        this.state.connect(documentChange$, () => INITIAL_STATE);

        // this will update the whole state with consistent state (including meta, chapters, index, ...) AFTER a route change
        this.state.connect(this.#afterActivatedRouteLoaded$, this.#onAfterActivatedRouteLoaded$);

        // This will keep `meta` state in sync with current focused chapter.
        // It is needed because we only update `meta` in case of a newly routed document (see above).
        this.state.connect(this.focusedChapter$, (state, focused) => ({
            ...state,
            meta: {
                ...this.meta,
                id: focused.id,
                title: focused.title,
            },
        }));

        // IMPORTANT: Don't connect the query to the router
        // There are cases where we do url manipulation without involving Angular's router (in order to not trigger the resolver).
        // That means that this model might end up in inconsistent state with current data (where a search is still present under the hood).
        const queryInSearchResult$ = this.routedDocument$.pipe(map(routed => routed.search?.query));
        this.state.connect('query', queryInSearchResult$);

        // the search cache we can update based on `search` in `routedDocument$`
        this.state.connect(
            'searchCache',
            queryInSearchResult$.pipe(
                filter(query => query && !this.state.get('searchCache').has(query)),
                map(() => {
                    const search = this.state.get('routed').search;
                    const old = this.state.get('searchCache') ?? ImmutableMap();
                    logger.debug(
                        this.#logPrefix,
                        'searchCache ->',
                        old.toJSON(),
                        'will update with',
                        search.query,
                        ':',
                        search.docIds.toJSON(),
                    );
                    return old.set(search.query, search.docIds.toSet());
                }),
            ),
        );

        // this will replace all loaded chapters with dummy ones in case of a query update
        // only those with hits corresponding to given query will be replaced
        // => this will lead to a reload of the chapter as soon as it gets visible in the viewport
        // => and this in return will ensure we have a consistent view regarding document search
        this.state.connect(
            combineLatest([this.documentChange$, this.#query$]).pipe(
                skip(1), // the first needs to be skipped (because search related data is consistent already)
                // let's get all the related chapter ids
                map(() => this.state.get('searchCache').get(this.query)),
                // we need to have `relatedIds` (value from `searchCache` corresponding to latest `query`)
                // otherwise we can't prepare the dummies...
                filter(Boolean),
            ),
            (state, relatedIds) => {
                const fontSize = this.fontSize;
                const width = this.ui.width;

                let chapterIndex = state.chapterIndex;
                // We need to set dummies for all chapters that are already present.
                // Present in state means those were searched for different query.
                // Since the query got updated we need to replace them with dummies in order to trigger a refresh again.
                const chapters = state.chapters.map(chapter => {
                    // The routed chapter is already up to date and MUST not be replaced with a dummy at all
                    // You'll end up in unexpected state and weird UI.
                    if (chapter.id === state.routed.document.id) {
                        chapterIndex = chapterIndex.set(
                            getChapterIndexKey(chapter.id, this.search),
                            state.routed.document,
                        );
                        return state.routed.document;
                    }

                    // we need to reset all chapters that weren't loaded yet for new query
                    // AND at the same time we need to keep our chapter in index in sync as well
                    if (!chapter.isDummy && relatedIds.has(chapter.id)) {
                        const dummy = createIDeskDocumentDummy(
                            this.injector,
                            {
                                id: chapter.id,
                                index: chapter.index,
                                docSize: chapter.docSize,
                                title: chapter.title,
                            },
                            { fontSize, width },
                        );
                        chapterIndex = chapterIndex.set(getChapterIndexKey(dummy.id, this.search), dummy);
                        return dummy;
                    }

                    // the chapter is either already a dummy => not loaded yet at all (that means it doesn't matter)
                    // OR it is a real one but, it doesn't contain any match for the query
                    return chapter;
                });

                logger.debug(
                    this.#logPrefix,
                    'routedDocument$ -> will update state with new chapters due to query change',
                    chapters.toArray(),
                );
                return { chapters, chapterIndex };
            },
        );

        this.state.connect(
            this.state.select('requestQueue').pipe(
                tap(idsToLoad => logger.debug(this.#logPrefix, 'requestQueue$ ->', idsToLoad.toArray())),
                // #1 we need to debounce because chapters will be added to queue (and removed again) right after they are visible
                //    if user scrolls fast over a long document, we would request backend for obsolete data
                debounceTime(DEBOUNCE_TIME_FOR_PENDING),
                filter(queue => queue.size > 0),
                // #2 ensuring that we don't request too many chapters at once (queue is limitless... & documents like BGB contain a lot of real small chapters...)
                map(queue => queue.slice(0, MAX_CHAPTER_REQUEST_AT_ONCE).toArray()),
                tap(idsToLoad => logger.debug(this.#logPrefix, 'requestQueue$ will request ->', idsToLoad)),
                // #3 request the chapters
                switchMap(idsToLoad => this.#requestRealChapters$(idsToLoad)),
                // #4 maybe we need to announce scroll position restoration
                this.#onDocumentsRequested,
                // #5 finally mapping to new state with new chapters we got
                this.#mapToChaptersUpdate,
            ),
        );

        const effects = rxEffects();

        const scrollToChapterTop = () => {
            const routed = this.state.get('routed');
            const restoreFocus: RestoreFocus = {
                anchor: routed.anchor,
                chapter: routed.document,
                offsetInPercent: 0,
            };
            logger.debug(this.#logPrefix, 'scrollToChapterTop -> will `restoreFocus`', restoreFocus);
            this.actions.restoreFocus(restoreFocus);
        };
        effects.register(this.routeChange$, scrollToChapterTop);

        const onRoutedAnchorChange$ = this.routedDocument$.pipe(
            map(routed => routed.anchor),
            filter(Boolean),
            withLatestFrom(this.routedDocument$),
        );

        const scrollToAnchor = ([anchor, routed]: [string, RoutedDocument]) => {
            logger.debug(
                this.#logPrefix,
                'routedDocument$ anchor change -> will restore focus to',
                anchor,
                'in',
                routed.document.id,
            );
            this.actions.restoreFocus({
                anchor,
                chapter: routed.document,
                offsetInPercent: 0,
            });
        };

        effects.register(onRoutedAnchorChange$, scrollToAnchor);

        // OPTIONAL(logging); only there for debugging; won't be active in PRODUCTION
        if (debug.document) {
            addDocumentModelDebugLogging(
                this.state,
                effects,
                this.#logPrefix,
                this.focusedAnchorId$,
                this.restoreFocus$,
            );
        }

        logger.debug(this.#logPrefix, 'initialised');
    }

    get chapters(): ImmutableList<Chapter> {
        return this.state.get('chapters') ?? ImmutableList<IDeskDocument>();
    }

    get focus(): FocusChapter {
        return this.state.get('focus');
    }

    get focusedAnchor(): string {
        return this.state.get('focus', 'anchor');
    }

    get focusedChapter(): Chapter {
        return this.state.get('focus', 'chapter') ?? this.routed?.document;
    }

    /**
     * If not set yet, you'll get a default value (observed from `document.body`).
     */
    get fontSize(): number {
        return this.state.get('ui', 'fontSize');
    }

    set fontSize(fontSize: number) {
        logger.debug(this.#logPrefix, 'fontSize ->', { new: fontSize, current: this.fontSize });
        if (this.fontSize === fontSize) {
            // we should ignore this; since moving on would involve also focus restoration => an expensive operation
            // (it could change scroll position when user opens a footnote)
            return;
        }
        this.state.set({ ui: { ...this.state.get('ui'), fontSize } });
        this.actions.restoreFocus(this.focus);
    }

    get meta(): DocumentMeta | undefined {
        return this.state.get('meta');
    }

    get query(): string {
        return this.state.get('query');
    }

    get routed(): RoutedDocument {
        return this.state.get('routed');
    }

    get rootId(): DocumentId | undefined {
        return this.state.get('meta')?.root.id;
    }

    get scrolling(): boolean {
        return this.state.get('ui', 'scrolling') ?? false;
    }

    set scrolling(scrolling: boolean) {
        logger.debug(this.#logPrefix, 'scrolling ->', scrolling);
        this.state.set({ ui: { ...this.state.get('ui'), scrolling } });
    }

    get search(): DocumentSearchResult {
        return this.state.get('routed', 'search');
    }

    set textSelection(value: TextSelection | undefined) {
        this.#textSelection = value;
    }

    get textSelection(): TextSelection | undefined {
        return this.#textSelection;
    }

    get title(): string {
        const title = this.state.get('meta')?.root?.title;
        return title ?? '';
    }

    get ui(): UI {
        return this.state.get('ui');
    }

    /**
     * Will change internal & published state.
     *
     * Used for storing information about chapters that are indeed finally rendered.
     * Should only be called from a component / directive / something UI related when being sure that
     * (the real & not the dummy) chapter related to given {@link documentId} is present in DOM for sure!
     *
     * This needs to updated accordingly also on document search (query) change. Use {@link query} for this.
     *
     * The information is used internally for preventing the model to request one and the same thing twice from backend.
     *
     * @param documentId
     * @param query
     *
     * @see request$
     */
    addRendered(documentId: DocumentId, query?: string) {
        const rendered = { ...this.state.get('ui', 'rendered') };
        rendered[documentId] = { query };
        logger.debug(this.#logPrefix, 'addRendered ->', documentId, { query }, rendered);
        this.state.set({
            ui: { ...this.state.get('ui'), rendered },
        });
    }

    /**
     * Calls this to inform model about its requested {@link restoreFocus$} being realised by UI.
     * This will clean up pending state inside the model and finally emit {@link focusedRestored$}.
     */
    focusRestored() {
        logger.debug(this.#logPrefix, 'focusRestored pendingFocus ->', false);
        const pending = this.#pending;
        this.state.set({
            pending: {
                documents: ImmutableSet(),
                waitingForFocusRestoration: false,
            },
            requestQueue: this.#requestQueue.merge(pending.documents),
        });
    }

    getChapter(id: DocumentId): Chapter | undefined {
        const key = getChapterIndexKey(id, this.search);
        const index = this.state.get('chapterIndex');
        const chapter = index?.get(key);
        if (!chapter) {
            logger.warn(this.#logPrefix, 'getChapter -> no chapter found for', id, key);
            return undefined;
        }
        return chapter;
    }

    /**
     * Will not request chapter for given {@param documentId} (if not done yet).
     */
    ignore(documentId: DocumentId) {
        const pending = this.#pending;
        const isPending = pending.documents.has(documentId);
        const isInRequestQueue = this.#requestQueue.has(documentId);
        // TODO: Can't we remove it only from the list it's found? Then maybe the debugging is clearer
        if (isPending || isInRequestQueue) {
            logger.debug(
                this.#logPrefix,
                'ignore -> removing',
                documentId,
                'from `pending` and `requestQueue`',
                'found in pending?',
                isPending,
                'found in requestQueue?',
                isInRequestQueue,
            );
            this.state.set({
                pending: {
                    ...pending,
                    documents: pending.documents.remove(documentId),
                },
                requestQueue: this.#requestQueue.remove(documentId),
            });
        }
    }

    /**
     * Will queue given {@param documentId}. In next request cycle it'll be requested as well.
     * You can undo this operation with {@see ignore}.
     *
     * Internally a {@link ImmutableSet} is used to avoid duplicates.
     */
    queue(documentId: DocumentId) {
        // there isn't any real content rendered yet ... let's not start our pending queue quite yet
        // this is because in the very first beginning of a document visit, we shortly see the very top of the document
        // AND that might not be the routed position...
        // In that case we have dummies at the top that would trigger not needed content if timing isn't in our favor.
        const pending = this.#pending;
        if (pending?.waitingForFocusRestoration) {
            logger.debug(this.#logPrefix, 'queue -> adding in `pending`', documentId);
            this.state.set({
                pending: {
                    ...pending,
                    documents: pending.documents.add(documentId),
                },
            });
            return;
        }

        logger.debug(this.#logPrefix, 'queue -> adding in `requestQueue`', documentId);
        this.state.set({ requestQueue: this.#requestQueue.add(documentId) });
    }

    /**
     * Requests given {@param documentId} immediately. Normally you should use {@link queue} instead.
     * But, e.g. for jump to next search hit, it might be useful to request the related chapter right away, to save some time.
     * Be aware, you still might need to wait for the rendering.
     *
     * This works only in context of search ({@link query} MUST be set).
     */
    request$(documentId: DocumentId): Observable<void> {
        logger.debug(this.#logPrefix, `request$ ->`, documentId);
        if (documentId === undefined) {
            logger.warn(this.#logPrefix, 'request$ -> somehow you ended up requesting `undefined`.');
            this.state.set({ backendError: true });
            return of(void 0);
        }

        // here we cover the case where requested chapter is already rendered => we don't need to request it again
        if (this.state.get('ui', 'rendered')?.[documentId]?.query === this.query) {
            logger.debug(this.#logPrefix, 'request$ -> chapter is already loaded');
            return of(void 0);
        }

        const related = this.state.get('chapterIndex')?.get(getChapterIndexKey(documentId, this.search));
        if (!related) {
            logger.warn(
                this.#logPrefix,
                "request$ -> somehow you ended up requesting a chapter that isn't part of current routed document.",
            );
            return of(void 0);
        }

        return this.#requestRealChapters$([documentId]).pipe(
            this.#mapToChaptersUpdate,
            tap(updatedState => this.state.set(updatedState)),
            map(() => void 0),
        );
    }

    setContainerSize(size: Pick<DOMRectReadOnly, 'height' | 'width'>): void {
        const width = Math.ceil(size.width);
        const height = Math.ceil(size.height);
        const currentSize: Pick<UI, 'height' | 'width'> | undefined = this.state.get('ui');
        if (currentSize?.height === height && currentSize?.width === width) {
            return;
        }

        logger.debug(this.#logPrefix, `setContainerSize ->`, width, 'x', height);
        this.state.set({
            ui: {
                ...this.state.get('ui'),
                width,
                height,
            },
        });
    }

    #requestRealChapters$(ids: DocumentId[]): Observable<IDeskDocument[]> {
        const query = this.routed.search?.resultsFound ? this.query : undefined;
        logger.debug(this.#logPrefix, `#requestRealChapters$ ->`, ids, { query });
        return this.service.getDocuments$(ids, query).pipe(
            this.debug.document_throttleBackendByMS ? delay(this.debug.document_throttleBackendByMS) : tap(noop),
            tap(chapters => {
                logger.debug(this.#logPrefix, '#requestRealChapters$ -> SUCCESS', ids, { query }, chapters);
                this.state.set({ backendError: false });
            }),
            catchError(error => {
                this.state.set({ backendError: true });
                logger.error(this.#logPrefix, '#requestRealChapters$ -> FAILED', ids, { query }, error);
                return of([]);
            }),
        );
    }

    get #initDebouncedFocusedChapter$(): Observable<Chapter> {
        return this.state.select('focus', 'chapter').pipe(
            debounceTime(200),
            immutableDistinctUntilChanged(),
            tap(focused => logger.debug(this.#logPrefix, 'debouncedFocusedChapter$ ->', focused.id, focused.title)),
            shareReplay(1),
        );
    }

    get #initDebouncedFocusedRealChapter$(): Observable<IDeskDocument> {
        return this.debouncedFocusedChapter$.pipe(
            filter(focused => !focused.isDummy),
            map(focused => focused as IDeskDocument),
            tap(focused => logger.debug(this.#logPrefix, 'debouncedFocusedRealChapter$ ->', focused.id, focused.title)),
            shareReplay(1),
        );
    }

    get #initFocusedAnchorId$(): Observable<string> {
        // we can't use `selectSlice` or `select(focus, 'anchor')` here because it'll filter undefined
        // BUT, we NEED the undefined value to be published
        return this.state.select('focus').pipe(
            map(focus => focus.anchor),
            distinctUntilChanged(),
        );
    }

    get #initFocusedRestored$(): Observable<void> {
        return this.state.select().pipe(
            map(state => state.pending?.waitingForFocusRestoration),
            distinctUntilChanged(),
            filter(waitingForFocusRestoration => waitingForFocusRestoration === false),
            map(() => void 0),
        );
    }

    get #initRestoreFocus$(): Observable<RestoreFocus> {
        return this.actions.restoreFocus$.pipe(filter(Boolean), shareReplay(1));
    }

    get #initRendered$(): Observable<QueryInDocument> {
        return this.state.select('ui').pipe(map(ui => ui?.rendered ?? {}));
    }

    get #initRouteChange$(): Observable<RoutedDocument> {
        // We hook on router events here because we want to ensure that every click on a document
        // (e.g. in a table-of-contents) WILL trigger "re-scroll" at every moment in every situation
        // For this it is important we have no `distinct` logic in place in any way.
        const routedDocId$ = inject(Router).events.pipe(
            filter(event => event.type === EventType.NavigationEnd),
            map(event => (event as NavigationEnd).url),
            filter(url => /\/document\/[HKL]I\d+/.test(url)),
            tap(url => logger.debug(this.#logPrefix, 'routeChange$ -> found document related URL', url)),
            map(url => documentIdFromUrl(url)),
            tap(id => logger.debug(this.#logPrefix, 'routeChange$ -> extracted `documentId` from URL change', id)),
        );

        // there is one thing you need to know about this:
        // => this model is an injectable that gets created only AFTER the very first document visit
        //    AND at that point of time the `NavigationEvent` we utilise here is already gone
        // => that means the very first `routeChange$` must be found in a different way
        //    (I use `routedDocument$` for that; it's safe since it is hooked into `routeParams` / `routeData$`)
        return merge(
            this.routedDocument$.pipe(first()),
            routedDocId$.pipe(
                switchMap(id =>
                    this.routedDocument$.pipe(
                        filter(routed => routed.document.id === id),
                        take(1),
                    ),
                ),
            ),
        ).pipe(
            tap(routedDocument => logger.debug(this.#logPrefix, 'routeChange$ -> DONE', routedDocument)),
            shareReplay(1),
        );
    }

    get #initToc$(): Observable<Toc> {
        return this.state.select('toc').pipe(
            distinctUntilChanged((toc1, toc2) => toc1.entries.get(0).documentId === toc2.entries.get(0).documentId),
            tap(toc => logger.debug(this.#logPrefix, 'toc$ ->', toc)),
            shareReplay(1),
        );
    }

    get #mapToChaptersUpdate(): OperatorFunction<IDeskDocument[], ChaptersUpdate> {
        return source$ =>
            source$.pipe(map(newChapters => this.#updateStateWithNewChapters(this.state.get(), newChapters)));
    }

    /**
     * Will ensure that we request <focus / scroll position> restoration if needed.
     * @private
     */
    get #onDocumentsRequested(): MonoTypeOperatorFunction<IDeskDocument[]> {
        return source$ =>
            source$.pipe(
                tap(requested => {
                    if (this.scrolling) {
                        logger.warn(
                            this.#logPrefix,
                            '#onDocumentsRequested -> ui is scrolling currently; falling back to pending focus restoration',
                            requested,
                        );
                        this.actions.restoreFocus({
                            ...this.#pending.focus,
                            waitFor: requested.map(chapter => chapter.id),
                        });
                        return;
                    }

                    // we only need to restore focus if content was added on top of current focus
                    // => since this is the only use case where scroll position will change due to different heights above
                    const willAddContentAbove = requested.some(chapter => chapter.index < this.focusedChapter.index);
                    if (!willAddContentAbove) {
                        logger.debug(
                            this.#logPrefix,
                            '#onDocumentsRequested -> only adding chapters below. Restoring focus ignored',
                            'requested indexes',
                            requested.map(chapter => chapter.index),
                            'focused index',
                            this.focusedChapter.index,
                        );
                        return;
                    }

                    // first we need to ensure we have correct values (offset percentages set in focus)
                    this.actions.computeFocus();

                    // now we request the focus restoration
                    const focus = this.focus;

                    // There is something off ...
                    // if we request focus restore on a dummy we'll end up in a stuck state where
                    //   - the component won't react anymore
                    //     It only restores focus for real chapters
                    //   - the model won't react anymore
                    //     Since while waiting for focus restoration all queued chapters stay in pending
                    //      => no requests anymore
                    //      => no new content anymore
                    if (!focus || focus.chapter.isDummy) {
                        logger.warn(
                            this.#logPrefix,
                            '#onDocumentsRequested -> focus is a dummy; will not restore focus',
                            focus,
                        );
                        return;
                    }

                    logger.debug(
                        this.#logPrefix,
                        '#onDocumentsRequested -> will update state and request restoring focus to documents:',
                        requested,
                        'focus',
                        focus,
                    );
                    this.actions.restoreFocus({
                        ...focus,
                        waitFor: requested.map(chapter => chapter.id),
                    });
                }),
            );
    }

    get #pending(): Pending | undefined {
        return this.state.get('pending');
    }

    get #pendingDocuments(): ImmutableSet<DocumentId> {
        return this.#pending?.documents ?? ImmutableSet();
    }

    get #requestQueue(): ImmutableSet<DocumentId> {
        return this.state.get('requestQueue') ?? ImmutableSet();
    }

    get #reuseLastRouteData(): boolean {
        // You can add this flag via programmatic routing (e.g. `RouterLink` in `TableOfContentsEntryComponent`)
        // (if not set; the model will reset completely on route change)
        return (this.location.getState() as any)?.reuseState; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    #updateStateWithNewChapters(state: Partial<DocumentState>, newChapters: IDeskDocument[]): ChaptersUpdate {
        logger.debug(
            this.#logPrefix,
            `#updateStateWithNewChapters -> loaded ${newChapters.length} chapters`,
            state,
            newChapters,
        );
        const chapters = ImmutableList.of(...update(state.chapters.toArray(), newChapters, (a, b) => a.id === b.id));
        let chapterIndex: ImmutableMap<DocumentId, Chapter> = state.chapterIndex ?? ImmutableMap();
        let requestQueue = this.#requestQueue;
        let pendingDocuments = this.#pendingDocuments;
        newChapters.forEach(chapter => {
            chapterIndex = chapterIndex.set(getChapterIndexKey(chapter.id, state.routed.search), chapter);
            pendingDocuments = pendingDocuments.remove(chapter.id);
            requestQueue = requestQueue.remove(chapter.id);
        });
        logger.debug(
            this.#logPrefix,
            '#updateStateWithNewChapters -> new:',
            newChapters,
            'merged:',
            chapters.toArray(),
            'index:',
            chapterIndex.toMap(),
        );
        return {
            chapters,
            chapterIndex,
            pending: {
                documents: pendingDocuments,
                waitingForFocusRestoration: this.#pending.waitingForFocusRestoration,
            },
            requestQueue,
        };
    }

    /**
     * Ensures a complete chapter list of the complete document.
     *
     * In case of a big multipart document you'll get a BIG list of ALL chapters in this document.
     * Every chapter is an "empty" dummy EXCEPT the focused (routed) one.
     */
    #createInitialChapters(routed: RoutedDocument): InitialChapters {
        const cachedChapterIndex = this.state.get('chapterIndex');
        const routedChapterIndexKey = getChapterIndexKey(routed.document.id, routed.search);

        // If possible lets return a cached version for initial chapters ...
        // Maybe the user didn't navigate to a different document.
        // For example, the user clicked in table of content in order to navigate to a different chapter...
        // In such cases we can re-use most of the state even on route change.
        // => navigation happened within the same document ...
        if (
            this.meta?.root.id === routed.meta.root.id &&
            // either same query
            (this.routed.search?.query === routed.search?.query ||
                // OR different query but no results AND we have it already requested without query / or no results
                (routed.search?.noResultsFound && cachedChapterIndex.has(routedChapterIndexKey)))
        ) {
            const cachedChapters = this.state.get('chapters');
            const replaceIndexInList = cachedChapterIndex.get(routedChapterIndexKey).index;
            const updatedCachedVersion: InitialChapters = {
                // the cached version might contain a dummy; that one we should replace already
                chapters: cachedChapters.set(replaceIndexInList, routed.document),
                chapterIndex: cachedChapterIndex.set(routed.document.id, routed.document),
            };
            logger.debug(this.#logPrefix, '#createInitialChapters -> using a cached version', updatedCachedVersion);
            return updatedCachedVersion;
        }

        let index = 0;

        let chapters = ImmutableList<Chapter>([routed.meta.root]);
        let chapterIndex = ImmutableMap<DocumentId, Chapter>([
            [getChapterIndexKey(routed.meta.root.id, routed.search), routed.meta.root],
        ]);

        const fillFromTocEntry = (tocEntry: TocEntry) => {
            // only create dummies for documents that weren't requested yet
            // (the routed document is already there and can be used right away; no further requests needed)
            const chapter =
                tocEntry.documentId === routed.document.id
                    ? routed.document
                    : // either we have it already or we create a dummy
                      (chapterIndex.get(getChapterIndexKey(tocEntry.documentId, routed.search)) ??
                      createIDeskDocumentDummy(
                          this.injector,
                          {
                              id: tocEntry.documentId,
                              index,
                              docSize: tocEntry.docSize,
                              title: tocEntry.title,
                          },
                          { fontSize: this.fontSize, width: this.ui.width },
                      ));
            index++;

            chapters = chapters.push(chapter);
            chapterIndex = chapterIndex.set(getChapterIndexKey(chapter.id, routed.search), chapter);
            if (!tocEntry.children) {
                return;
            }

            tocEntry.children.forEach(child => fillFromTocEntry(child));
        };

        routed.meta.toc.entries.forEach(tocEntry => fillFromTocEntry(tocEntry));

        logger.debug(
            this.#logPrefix,
            '#createInitialChapters ->',
            'created chapters for toc',
            routed.meta.toc,
            'with routed document id',
            routed.document.id,
            'chapter list:',
            chapters.toJS(),
        );

        return { chapters, chapterIndex };
    }

    get #onAfterActivatedRouteLoaded$(): ProjectStateReducer<DocumentState, RoutedDocument> {
        // covers 2 cases ...
        //  (1) either we are within the same whole document => we only need to update a part of the state
        //  (2) complete different document => we reset the state with new data
        return (state, routed) => {
            const meta: DocumentMeta = routed.meta;
            const focused = routed.document;
            const focus: FocusChapter = {
                chapter: focused,
                offsetInPercent: 0,
                ...(routed.anchor ? { anchor: routed.anchor } : {}),
            };

            // we stay within the same whole document we are already in
            if (this.#reuseLastRouteData && meta.root.id === state.meta?.root.id) {
                const chapters = state.chapters;
                const updatedState = {
                    chapters: chapters.set(focused.index, focused),
                    chapterIndex: state.chapterIndex.set(getChapterIndexKey(focused.id, routed.search), focused),
                    focus,
                    routed,
                };
                logger.debug(this.#logPrefix, '#onAfterActivatedRouteLoaded$ updating state ->', updatedState, routed);
                return updatedState;
            }

            const { chapters, chapterIndex } = this.#createInitialChapters(routed);

            const newState = {
                chapters,
                chapterIndex,
                focus,
                initialLoading: false,
                meta,
                pending: {
                    documents: ImmutableSet(),
                    waitingForFocusRestoration: true,
                },
                query: routed.search?.query,
                routed,
                requestQueue: ImmutableSet(),
                searchCache: routed.search
                    ? ImmutableMap({
                          [routed.search.query]: ImmutableSet<string>(routed.search.docIds),
                      })
                    : ImmutableMap(),
                toc: meta.toc,
                ui: {
                    ...(state.ui ?? {}),
                    rendered: {},
                },
            };
            logger.debug(
                this.#logPrefix,
                '#onAfterActivatedRouteLoaded$ updating now initial state ->',
                newState,
                routed,
            );
            return newState;
        };
    }

    get #afterActivatedRouteLoaded$(): Observable<RoutedDocument> {
        // ui is ready by definition when we have `height` & `width` set
        const uiIsReady$ = this.state.select('ui').pipe(
            map(ui => ui.height > 0 && ui.width > 0),
            filter(Boolean),
            take(1),
            tap(() => logger.debug(this.#logPrefix, 'uiIsReady$ ->', true)),
        );

        // Be careful to use `combineLatest` here => you'll get 2 notifications for one route change
        // But, this model only expects one on route change.
        // When the route changes the resolver will be triggered and `data` will emit.
        // As a result it is safe to use `withLatestFrom` for `paramMap` here.
        const routedDocument$ = this.#routeData$.pipe(
            filter(Boolean),
            withLatestFrom(this.#routeParams$),
            tap(([data, params]) => logger.debug(this.#logPrefix, 'routeData$/routeParams$ ->', data, params)),
            map(
                ([data, params]) =>
                    ({
                        ...data.document,
                        ...(params.anchor ? { anchor: params.anchor } : {}),
                        // since we stay within the whole document we should keep the latest search result
                        // => that way we keep up the highlighting, search navigation enabled & query in search footer
                        ...(this.#reuseLastRouteData && this.routed?.search ? { search: this.routed.search } : {}),
                    }) as RoutedDocument,
            ),
            tap(routed => logger.debug(this.#logPrefix, 'routedDocument$ ->', routed)),
            shareReplay(1),
        );

        // an observable that emits right after ui is ready and `routedDocument$` being available as well
        return uiIsReady$.pipe(
            switchMap(() => routedDocument$),
            tap(routed => logger.debug(this.#logPrefix, '#afterActivatedRouteLoaded$ ->', routed)),
            shareReplay(1),
        );
    }
}
