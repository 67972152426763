import { Inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { ReloadedUserSettingsDTO } from '@idr/shared/model';
import {
    containsHot,
    containsNews,
    containsTopicPage,
    documentIdFromUrl,
    logger,
    startsWithProductId,
} from '@idr/shared/utils';
import { RoutedDocument } from '@idr/ui/document';
import { ProfileService, Realm, REALM, RouterEvents, stripHtml, UserSettingsService } from '@idr/ui/shared';
import {
    GtmCurrentDocumentData,
    GtmEvents,
    GtmOutputmanagerEvent,
    GtmOutputmanagerEventType,
    GtmOutputValues,
    GtmPageChangeEvent,
    GtmSearchEvent,
    GtmUserIdEvent,
    GtmUserSettingsChangedEvent,
    isAllowedToBeTracked,
} from '@idr/ui/tracking';
import { rxEffects } from '@rx-angular/state/effects';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { delay, skip, take, withLatestFrom } from 'rxjs/operators';
import { HotService } from '../../hot-page/services/hot.service';
import { NewsService } from '../../news-page/services/news.service';
import { SearchResult } from '../../search-page/model/search-result';
import { SearchSuggestion } from '../../shared/components/search-input/suggestion/search-suggestion';
import { IDeskTopicPage } from '../../topic-page';
import { findInData } from '../../utils/route/activated-route-helpers';
import { queueUntil } from '../../utils/rxjs';
import { ConsentTrackingService } from '../consent/consent-tracking.service';
import { SuggestionService } from '../suggestion/suggestion.service';
import { createGtmCurrentDocumentData, distinctSearchResult } from './tracking-helpers';

@Injectable({ providedIn: 'root' })
export class GtmTrackingService {
    readonly #logPrefix = '[GtmTrackingService]';
    readonly #searchResults = signal<SearchResult>(undefined);

    searchResults$ = toObservable(this.#searchResults).pipe(
        filter(it => !!it),
        distinctUntilChanged(distinctSearchResult),
    );

    constructor(
        private readonly gtmService: GoogleTagManagerService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly hotService: HotService,
        private readonly newsService: NewsService,
        routerEvents: RouterEvents,
        profileService: ProfileService,
        consentService: ConsentTrackingService,
        userSettingsService: UserSettingsService,
        @Inject(REALM) authRealm: Realm,
        suggestionService: SuggestionService,
    ) {
        const user$: Observable<Pick<GtmUserIdEvent, 'userId' | 'isHaufeInternal'>> = profileService.isAnonymous$.pipe(
            queueUntil(consentService.consent$),
            switchMap(isAnonymous => (isAnonymous ? of('anonymous') : profileService.userId$)),
            withLatestFrom(profileService.isEmailHaufeInternal$),
            map(([userId, isHaufeInternal]) => ({ userId, isHaufeInternal })),
            shareReplay(1),
        );

        const userSettings$ = userSettingsService.userSettingsWithoutFallback$.pipe(
            queueUntil(user$),
            // we skip the very first one because it will recieve an increment of the visitCount
            // and that leads to an update therefore two emissions at start
            skip(1),
            shareReplay(1),
        );

        const navigationEndUrl$: Observable<GtmPageChangeEvent> = routerEvents.navigationEnd$.pipe(
            // for some reason the delay of 1 is needed to be sure it emits really after
            queueUntil(userSettings$.pipe(delay(1))),
            map((event: NavigationEnd) => event.url),
            filter(url => startsWithProductId(url)),
            distinctUntilChanged(),
            tap(url => logger.debug(this.#logPrefix, 'got navigationEnd$ url ->', url)),
            switchMap(url => this.#createPageChangeEvent(url)),
            filter(it => isAllowedToBeTracked(it)),
            shareReplay(1),
        );

        rxEffects(({ register }) => {
            register(navigationEndUrl$, event => this.#sendPageChange(event));
            register(user$, user => this.#sendUserId(user, authRealm));
            register(userSettings$, userSettings => this.#sendUserSettings(userSettings));

            register(this.searchResults$, searchResult =>
                this.#sendSearchEvent(searchResult, suggestionService.suggestions()),
            );
        });
    }

    get #documentFromRoute(): RoutedDocument | undefined {
        return findInData<RoutedDocument>(this.activatedRoute, 'document');
    }

    get #topicPage(): IDeskTopicPage {
        return findInData<IDeskTopicPage>(this.activatedRoute, 'topicPage');
    }

    setSearchResult(searchResult: SearchResult) {
        if (!searchResult) {
            return;
        }
        this.#searchResults.set(searchResult);
    }

    #sendSearchEvent(searchResult: SearchResult, suggestions: SearchSuggestion[]) {
        const searchEvent: GtmSearchEvent = {
            event: 'SearchEvent',
            appliedSuggestion: suggestions.some(it => it.selected),
            searchTermBeforeAutoSuggest: suggestions.find(it => it.selected)?.prefix,
            suggestions,
            searchResult,
        };

        logger.debug(this.#logPrefix, 'send SearchEvent ->', searchEvent);
        return this.gtmService.pushTag(searchEvent);
    }

    sendOutputManagerEvent(formValues: GtmOutputValues, eventType: GtmOutputmanagerEventType) {
        const gtmEvent: GtmOutputmanagerEvent = {
            event: 'OutputmanagerEvent',
            eventType,
            formValues,
        };

        logger.debug(this.#logPrefix, 'send OutputmanagerEvent ->', gtmEvent);
        return this.gtmService.pushTag(gtmEvent);
    }

    #sendUserSettings(userSettings: ReloadedUserSettingsDTO) {
        const event: GtmUserSettingsChangedEvent = {
            event: GtmEvents.UserSettingsChanged,
            userSettings,
        };
        logger.debug(this.#logPrefix, 'send UserSettings ->', event);
        return this.gtmService.pushTag(event);
    }

    #sendUserId({ userId, isHaufeInternal }: Pick<GtmUserIdEvent, 'userId' | 'isHaufeInternal'>, realm: Realm) {
        const event: GtmUserIdEvent = {
            event: GtmEvents.UserId,
            userId,
            isHaufeInternal,
            authRealm: realm,
        };
        logger.debug(this.#logPrefix, 'send UserId ->', event);
        return this.gtmService.pushTag(event);
    }

    async #sendPageChange(event: GtmPageChangeEvent) {
        logger.debug(this.#logPrefix, 'send GtmPageChangeEvent ->', event);
        return this.gtmService.pushTag(event);
    }

    #getNewsData(): Observable<GtmCurrentDocumentData> {
        return this.newsService.newsDocument$.pipe(
            map(data => ({
                rootTitle: data.title,
                rootId: data.contentHubId,
                docType: data.classification?.type.toUpperCase(),
                docDisplayType: data.classification?.description,
                docSubType: data.classification.subType.toUpperCase(),
            })),
        );
    }

    #getHotData(): Observable<GtmCurrentDocumentData> {
        return this.hotService.hotDocument$.pipe(
            take(1),
            map(data => ({
                rootTitle: data.title,
                rootId: data.contentHubId,
                docType: 'HOT',
                docDisplayType: data.classification?.description,
                docSubType: data.classification?.subType.toUpperCase(),
            })),
        );
    }

    #getTopicPageData(topicPage: IDeskTopicPage): GtmCurrentDocumentData {
        return {
            rootId: topicPage.docId,
            rootTitle: stripHtml(topicPage.title),
            docType: 'THEMENSEITE',
            docDisplayType: 'Themenseite',
            docSubType: 'THEMENSEITE',
        };
    }

    async #createPageChangeEvent(url: string): Promise<GtmPageChangeEvent> {
        let currentDocument: GtmCurrentDocumentData;

        if (containsHot(url)) {
            currentDocument = await firstValueFrom(this.#getHotData());
        } else if (containsNews(url)) {
            currentDocument = await firstValueFrom(this.#getNewsData());
        } else if (containsTopicPage(url) && !documentIdFromUrl(url)) {
            currentDocument = this.#getTopicPageData(this.#topicPage);
        } else {
            currentDocument = createGtmCurrentDocumentData(this.#documentFromRoute);
        }

        return {
            event: GtmEvents.PageChange,
            url: url,
            currentDocument: currentDocument,
        };
    }
}
