import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { Params, ProductId } from '@idr/shared/model';
import { ActiveProduct, LoadingDotsComponent } from '@idr/ui/shared';
import { GtmIdDirective } from '@idr/ui/tracking';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppStateService, Sitemap } from '../../../../core';

/**
 * Component is inspired by W3C example.
 *
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/#breadcrumb
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, RouterLink, MatIconModule, RxFor, RxIf, RxLet, LoadingDotsComponent, GtmIdDirective],
    selector: 'idr-sitemap',
    standalone: true,
    styleUrls: ['./sitemap.component.scss'],
    templateUrl: './sitemap.component.html',
})
export class SitemapComponent {
    // https://www.w3.org/TR/wai-aria-1.1/#navigation
    @HostBinding('attr.role') readonly role: string = 'navigation';
    @HostBinding('attr.aria-label') readonly ariaLabel: string = 'Seitenbaum';

    readonly isSearch$: Observable<boolean>;
    readonly lastSearchRouterLink$: Observable<[string, ProductId, 'search', string, Params] | undefined>;
    readonly sitemap$: Observable<Sitemap>;

    constructor(appState: AppStateService, product: ActiveProduct) {
        this.lastSearchRouterLink$ = appState.lastSearch$.pipe(
            map(lastSearch =>
                lastSearch ? ['/', product.id, 'search', lastSearch.query, lastSearch.params] : undefined,
            ),
        );
        this.sitemap$ = appState.sitemap$;
        // related to NAUA-5412... we abbreviate all items in the sitemap but we shouldn't for a search...
        // (since there we can have longer text that needs to be visible completely)
        this.isSearch$ = this.sitemap$.pipe(
            filter(sitemap => sitemap?.length >= 1),
            map(sitemap => /Suchergebnis.*/.test(sitemap[sitemap.length - 1].label as string)),
        );
    }
}
