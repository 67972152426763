import { Injectable } from '@angular/core';
import { DocumentId, NormKey } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentModel } from '../../../document/state';
import { AppStateService } from '../../app-state/app-state.service';
import { NavigationService } from '../../navigation/navigation.service';
import { CrsDocumentService } from '@idr/ui/crs-facade';

@Injectable()
export class DocumentVersionService {
    constructor(
        private readonly appState: AppStateService,
        private readonly crsDocumentService: CrsDocumentService,
        private readonly model: DocumentModel,
        private readonly navigate: NavigationService,
    ) {}

    public async openDocumentVersion(normKey: NormKey): Promise<boolean> {
        // The busy app state will be set to back to "false" by the RouterEventsDirective when needed
        this.appState.busy = true;
        const focusedDocId: DocumentId = this.model.focusedChapter.id;

        // Checking if we are in the root document chapter
        if (focusedDocId === this.model.rootId) {
            return this.goToVersionedDocument(normKey, focusedDocId, true);
        }

        // We are in a subchapter
        // Checking if we are already in a versioned document view
        if (focusedDocId.match(/HI+\d*\.HI+\d*/)) {
            // currentVersionDocId is the part after the '.' in the focusedDocId when we are viewing a versioned document
            const currentVersionDocId: DocumentId = focusedDocId.replace(/HI+\d*\./, '');

            // Checking if we selected the current version
            if (normKey.match(/^aktuell/)) {
                return this.goToVersionedDocument(undefined, currentVersionDocId);
            }

            // We didn't select the current version
            return this.goToVersionedDocument(normKey, currentVersionDocId);
        }

        // We are not in a versioned doc view
        return this.goToVersionedDocument(normKey, focusedDocId);
    }

    private async goToVersionedDocument(
        normKey: NormKey,
        focusedDocId: DocumentId,
        isRootId = false,
    ): Promise<boolean> {
        let targetDocId: DocumentId = focusedDocId;
        // Current version has undefined normKey
        if (normKey) {
            targetDocId = await firstValueFrom(this.getVersionedDocumentIdFor$(normKey, focusedDocId, isRootId));
        }
        return this.navigate.toDocument(targetDocId);
    }

    /**
     * Returns the document id for the requested version.
     *
     * @param normKey represents the version identifier
     * @param focusedDocId the document id of the chapter which is currently focused
     * @param isRootId (default false) set to true if focusedDocId is the root doc id
     */
    private getVersionedDocumentIdFor$(
        normKey: NormKey,
        focusedDocId: DocumentId,
        isRootId = false,
    ): Observable<DocumentId> {
        return this.crsDocumentService.getRootDocumentIdForVersion$(normKey).pipe(
            map((versionedRootDocId: DocumentId) => {
                return [isRootId ? versionedRootDocId : `${versionedRootDocId}.${focusedDocId}`, versionedRootDocId];
            }),
            switchMap(([targetDocId, versionedRootDocId]: [DocumentId, DocumentId]) =>
                // There are cases that the generated document id for the specific version does not exist.
                // We identify those cases by making a request to CRS proactively.
                // Then we redirect the user to the root document of the requested version.
                this.crsDocumentService.getDocument$(targetDocId).pipe(
                    map(() => targetDocId),
                    catchError(err => {
                        logger.warn(
                            "[DocumentVersionService] getVersionedDocumentIdFor$ -> ERROR (falling back to root id). Couldn't resolve document",
                            targetDocId,
                            err,
                        );
                        return of(versionedRootDocId);
                    }),
                ),
            ),
        );
    }
}
