import { Inject, Injectable } from '@angular/core';
import { ContentType, HotConfig } from '@idr/shared/model';
import { HotDocument } from '@idr/ui/content-hub';
import { ActiveProduct, REALM, Realm, RouterEvents } from '@idr/ui/shared';
import { Observable } from 'rxjs';
import { ContentHubContentService } from '../../content-hub/services/content-hub-content.service';
import { ActiveStartPage, NavigationService } from '../../core';
import { ContentHubService } from '../../core/contenthub/content-hub.service';
import { DocumentHistory } from '../../core/history/document-history';

@Injectable({ providedIn: 'root' })
export class HotService extends ContentHubContentService<HotDocument> {
    public readonly hotDocument$: Observable<HotDocument>;

    constructor(
        navigationService: NavigationService,
        routerEvents: RouterEvents,
        contentHubService: ContentHubService,
        activeProduct: ActiveProduct,
        documentHistory: DocumentHistory,
        activePage: ActiveStartPage,
        @Inject(REALM) realm: Realm,
    ) {
        super(
            navigationService,
            routerEvents,
            contentHubService,
            HotDocument.fromDocumentDTO,
            activeProduct,
            documentHistory,
            realm,
            activePage.getConfig$<HotConfig>(ContentType.HOT),
        );
        this.hotDocument$ = this.contentHubDocument$;
    }
}
