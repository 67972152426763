import { Inject, Injectable } from '@angular/core';
import { DebugSettings, DocumentId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { DEBUG_SETTINGS, DocumentSearchResult, LocationService } from '@idr/ui/shared';
import { rxState } from '@rx-angular/state';
import { rxEffects } from '@rx-angular/state/effects';
import { RxState } from '@rx-angular/state/lib/rx-state';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { immutableDistinctUntilChanged } from '../../utils/rxjs';
import { DocumentTrackingService } from '../services/document-tracking/document-tracking.service';
import { DocumentModel } from './document-model';
import { DocumentSearchDirection, DocumentSearchState, HighlightsPerChapter, NextFocus, RestoreFocus } from './types';

type NextFocusWithRenderedHits = NextFocus & { readonly renderedHits?: NodeListOf<HTMLElement> };

@Injectable()
export class DocumentSearchModel {
    // needs to be implemented first since everything else depends on it
    public readonly state: RxState<DocumentSearchState> = rxState<DocumentSearchState>();

    public readonly focusedHit$: Observable<HTMLElement> = this.initFocusedHit$;

    public readonly highlightsPerChapter$: Observable<HighlightsPerChapter> = this.initHighlightsPerChapter$;

    public readonly query$: Observable<string> = this.initQuery$;

    /**
     * Can have 4 states.
     * - undefined => no search submitted => no prev/next buttons will be displayed in the UI and no error message
     * - DocumentSearchResult.isLoading===true => Loading indicator is shown in the UI.
     * - empty DocumentSearchResult (DocumentSearchResult.noResultsFound()===true) => "nothing found" error displayed
     * - DocumentSearchResult with results => prev/next buttons are displayed
     */
    public readonly searchResult$: Observable<DocumentSearchResult> = this.initSearchResult$;

    /**
     * Depends on {@link searchResult$}.
     *
     * submitted and no result found
     */
    public readonly noResultsFound$: Observable<boolean> = this.initNoResultsFound$;

    /**
     * Depends on {@link searchResult$}.
     *
     * submitted and results found
     */
    public readonly resultsFound$: Observable<boolean> = this.initResultsFound$;

    /**
     * `true` always when a search is in progress / got submitted but hasn't returned yet with a result.
     */
    public readonly isLoading$: Observable<boolean> = this.initIsLoading$;

    public readonly nextButtonDisabled$: Observable<boolean> = this.initNextButtonDisabled$;

    public readonly prevButtonDisabled$: Observable<boolean> = this.initPrevButtonDisabled$;

    private readonly logPrefix = '[DocumentSearchModel]';

    constructor(
        @Inject(DEBUG_SETTINGS) debug: DebugSettings,
        private readonly model: DocumentModel,
        private readonly location: LocationService,
        private readonly tracking: DocumentTrackingService,
    ) {
        // the current position must always be reset on a route change
        this.initResetCurrentPositionOnNewDocument();

        this.initResetStateOnRouteChange();

        const effects = rxEffects();

        // when `nextFocus` emits we need to take action
        // => we need to ensure related chapter gets requested (if not done yet)
        // => we need to wait for DOM to be up-to-date
        //    we ensure this via `highlightsPerChapter` which will be updated by the chapter itself as soon as it got rendered
        // => we tell the model to restore focus based on `searchHit`
        // => we update our internal state
        effects.register(this.nextFocusAfterHitsAreRendered$, this.updateStateOnNextFocusAfterHitsAreRendered$);

        effects.register(
            model.routeChange$.pipe(
                filter(routed => routed.search?.resultsFound),
                map(routed => routed.document?.id),
            ),
            routed => this.activateFirstInChapter(routed),
        );

        if (debug.document_searchHitIndicators) {
            effects.register(this.state.select('highlightsPerChapter'), this.setDebugAttributesOnHitsUpdate);
        }

        logger.debug(this.logPrefix, 'initialised');
    }

    public get chapterIdsWithHits(): ImmutableList<DocumentId> {
        return this.state.get('searchResult', 'docIds');
    }

    public get empty(): boolean {
        const searchResult = this.state.get('searchResult');
        return searchResult === undefined || searchResult.noResultsFound;
    }

    public get result(): DocumentSearchResult {
        return this.state.get('searchResult');
    }

    /**
     * Return the query string that is currently in the url. (eg query=person)
     */
    public get query(): string {
        return this.state.get('query');
    }

    public activateFirstInChapter(chapterId: DocumentId): void {
        if (this.empty) {
            return;
        }

        const chapterIndex = this.chapterIdsWithHits?.findIndex(id => id === chapterId);
        if (!chapterIndex) {
            logger.warn(this.logPrefix, 'activateFirstInChapter -> ignored; not initialised correctly', chapterId);
            return;
        }

        logger.debug(this.logPrefix, 'activateFirstInChapter ->', chapterId);
        this.state.set({
            direction: DocumentSearchDirection.Forward,
            hasPrevious: true,
            nextFocus: {
                chapterId,
                chapterIndex,
                scrollIntoView: true,
            },
        });
    }

    /**
     * Call this when user wants to go to next search hit in current document.
     */
    public goToNext(): void {
        if (this.empty) {
            return;
        }

        logger.debug(this.logPrefix, 'goToNext');
        const update = (nextFocus: NextFocus) =>
            this.state.set({
                direction: DocumentSearchDirection.Forward,
                hasPrevious: true,
                // hasNext shall always be true for at least 2 hits... (see above)
                nextFocus: {
                    ...nextFocus,
                    scrollIntoView: true,
                },
            });

        const chapterIdsWithHits = this.chapterIdsWithHits;
        const current = this.state.get('currentPosition');
        if (current) {
            const hitCountInCurrentChapter = this.getHighlightsFor(current.chapterId).length;
            const chapterHasMoreHits = current.hitIndex + 1 < hitCountInCurrentChapter;
            if (chapterHasMoreHits) {
                return update({
                    chapterId: current.chapterId,
                    chapterIndex: current.chapterIndex,
                    hitIndex: current.hitIndex + 1,
                });
            }

            // EDGE CASE: last chapter => jump to first chapter & first hit
            if (current.chapterIndex + 1 === chapterIdsWithHits.size) {
                const nextChapterId = chapterIdsWithHits.first();
                return update({
                    chapterId: nextChapterId,
                    chapterIndex: 0,
                    hitIndex: 0,
                });
            }

            const chapterIndex = current.chapterIndex + 1;
            const nextChapterId = chapterIdsWithHits.get(chapterIndex);
            return update({
                chapterId: nextChapterId,
                chapterIndex,
            });
        }

        // EDGE CASE: there are hits but user moved away via link or toc interaction => nothing is focused right now
        update({
            chapterId: chapterIdsWithHits.first(),
            chapterIndex: 0,
        });
    }

    /**
     * Call this when user wants to go to previous search hit in current document.
     */
    public goToPrevious(): void {
        if (this.empty) {
            return;
        }

        logger.debug(this.logPrefix, 'goToPrevious');
        const current = this.state.get('currentPosition');
        const chapterHasMoreHits = current.hitIndex - 1 >= 0;

        let nextFocus: NextFocus;
        if (chapterHasMoreHits) {
            nextFocus = {
                chapterId: current.chapterId,
                chapterIndex: current.chapterIndex,
                hitIndex: current.hitIndex - 1,
                scrollIntoView: true,
            };
        } else {
            const nextChapterId = this.chapterIdsWithHits.get(current.chapterIndex - 1);
            nextFocus = {
                chapterId: nextChapterId,
                chapterIndex: current.chapterIndex - 1,
                ...(this.model.getChapter(nextChapterId).isDummy ? { behavior: 'instant' } : {}),
                scrollIntoView: true,
            };
        }

        this.state.set({
            direction: DocumentSearchDirection.Backward,
            // when it is the first chapter, we still might have previous hits
            // => BUT, we can't tell at this point of time for sure
            // This is because the chapter might not be loaded yet completely.
            // AND until it isn't rendered completely we don't have all hits in DOM, so we can't tell...
            hasPrevious: nextFocus.chapterIndex > 0,
            // hasNext shall always be true for at least 2 hits... (see above)
            nextFocus,
        });
    }

    public registerHighlights(id: DocumentId, highlights: NodeListOf<HTMLElement>) {
        const currentIndex = this.state.get('highlightsPerChapter');
        logger.debug(this.logPrefix, 'registerHighlights ->', currentIndex.toJSON(), id, highlights);

        // There is one case; if there is only one chapter involved we have `hasNext` set to `false`
        // BUT, if there is more than one hit in this very one chapter, `hasNext` should be set to `true`.
        //
        // ALSO, we MUST not set `hasNext` to `false` here because it would corrupt the state.
        //      Think of first chapter registers with 2 hits and right afterwards another chapter registers with only 1 hit.
        if (highlights.length > 1) {
            this.state.set({ hasNext: highlights.length > 1 });
        }

        this.state.set({ highlightsPerChapter: currentIndex.set(id, highlights) });
    }

    public submit(query: string): Promise<boolean> {
        logger.debug(this.logPrefix, 'submit ->', { query });

        if (!query && !this.query) {
            logger.debug(
                this.logPrefix,
                'submit -> ignored since given query is empty & current state is already empty as well',
            );
            return Promise.resolve(true);
        }

        // triggered again should navigate the user back to the very first hit in the related document
        const sameSearch = this.result?.resultsFound && this.result.query === query;
        if (sameSearch) {
            logger.debug(this.logPrefix, 'submit; given query matches current state -> navigating back to first hit');
            this.tracking.trackDocSearch(query);
            this.state.set({
                direction: DocumentSearchDirection.Forward,
                nextFocus: {
                    chapterId: this.chapterIdsWithHits.first(),
                    chapterIndex: 0,
                    scrollIntoView: true,
                },
            });
            return Promise.resolve(true);
        }

        // when reset we need to update query to undefined => this ensures listeners of `query$` get notified
        // since we don't trigger resolver (see below), we don't get a new `searchResult` and `query$` update would be missing
        const reset = !query;
        if (reset) {
            this.state.set({ query: undefined });
        } else {
            this.tracking.trackDocSearch(query);
        }

        logger.debug(this.logPrefix, 'submit; encountered new query -> will update history');
        return this.location.updateQueryParameters(
            { query, force: undefined },
            // resolvers should be triggered when we got a query (also we need a history entry for that)
            // no query means => no new data is needed; also focus should just stay right where it is
            reset ? { triggerResolvers: false } : undefined,
        );
    }

    private get goForward(): boolean {
        return this.state.get('direction') === DocumentSearchDirection.Forward;
    }

    private get initFocusedHit$(): Observable<HTMLElement> {
        return this.state.select().pipe(
            distinctUntilChanged(
                (previous, current) =>
                    previous.searchResult?.query === current.searchResult?.query &&
                    previous.currentPosition?.chapterId === current.currentPosition?.chapterId &&
                    previous.currentPosition?.chapterIndex === current.currentPosition?.chapterIndex &&
                    previous.currentPosition?.hitIndex === current.currentPosition?.hitIndex &&
                    previous.highlightsPerChapter?.equals(current.highlightsPerChapter),
            ),
            map(state =>
                state.currentPosition && state.highlightsPerChapter?.size > 0
                    ? state.highlightsPerChapter
                          .get(state.currentPosition.chapterId)
                          .item(state.currentPosition.hitIndex)
                    : undefined,
            ),
            distinctUntilChanged(),
            tap(focusedHit => logger.debug(this.logPrefix, 'focusedHit$ ->', focusedHit)),
            shareReplay(1),
        );
    }

    private get initHighlightsPerChapter$(): Observable<HighlightsPerChapter> {
        return this.state.select('highlightsPerChapter').pipe(
            tap(_state => logger.debug(this.logPrefix, 'highlightsPerChapter$ ->', _state.toJSON())),
            shareReplay(1),
        );
    }

    private get initIsLoading$(): Observable<boolean> {
        return this.state.select('isLoading').pipe(
            tap(value => logger.debug(this.logPrefix, 'isLoading$ ->', value)),
            shareReplay(1),
        );
    }

    private get initNextButtonDisabled$(): Observable<boolean> {
        return this.state.select('hasNext').pipe(
            map(hasNext => !hasNext),
            distinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'nextButtonDisabled$ ->', value)),
            shareReplay(1),
        );
    }

    private get initNoResultsFound$(): Observable<boolean> {
        return this.searchResult$.pipe(
            map(results => !!results?.noResultsFound),
            distinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'noResultsFound$ ->', value)),
            shareReplay(1),
        );
    }

    private get initPrevButtonDisabled$(): Observable<boolean> {
        return this.state.select('hasPrevious').pipe(
            map(hasPrevious => !hasPrevious),
            distinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'prevButtonDisabled$ ->', value)),
            shareReplay(1),
        );
    }

    private get initResultsFound$(): Observable<boolean> {
        return combineLatest([this.searchResult$, this.query$]).pipe(
            map(([results, query]) => results?.resultsFound && !!query),
            distinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'resultsFound$ ->', value)),
            shareReplay(1),
        );
    }

    private get initSearchResult$(): Observable<DocumentSearchResult> {
        return this.state.select().pipe(
            map(state => state.searchResult),
            immutableDistinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'searchResults$ ->', value)),
            shareReplay(1),
        );
    }

    private get initQuery$(): Observable<string> {
        return this.state.select().pipe(
            // we need to map it this way;
            // `select('query')` would filter out undefined (e.g. cleared search), which leads to unexpected behaviour
            map(state => state.query),
            distinctUntilChanged(),
            tap(value => logger.debug(this.logPrefix, 'query$ ->', value)),
            shareReplay(1),
        );
    }

    private get nextFocus(): NextFocus {
        return this.state.get('nextFocus');
    }

    private get nextFocusAfterHitsAreRendered$(): Observable<NextFocusWithRenderedHits> {
        const hasHighlights = (documentId: DocumentId) =>
            // our internal `searchResult` state might not be set yet
            this.model.routed.search.docIds.contains(documentId);

        const whenHighlightsAreRendered$ = this.highlightsPerChapter$.pipe(
            map(highlightsPerChapter => {
                const nextFocus = this.nextFocus;

                // meanwhile the `nextFocus` might have changed
                if (nextFocus === undefined) {
                    return undefined;
                }

                return highlightsPerChapter.get(nextFocus.chapterId);
            }),
            filter(Boolean),
            take(1),
        );

        const whenChapterIsRendered$ = this.model.rendered$.pipe(
            filter(rendered => {
                const nextFocus = this.nextFocus;
                return (
                    nextFocus !== undefined && // meanwhile the `nextFocus` might have changed
                    rendered[nextFocus.chapterId] !== undefined
                );
            }),
            map(() => undefined),
            take(1),
        );

        return this.state.select('nextFocus').pipe(
            filter(Boolean),
            tap(nextFocus => logger.debug(this.logPrefix, 'nextFocus$ ->', nextFocus)),
            // we need to ensure that chapter is available;
            // it doesn't hurt to call it; the model takes care and will ignore the request if it is already present
            switchMap(identifier => this.model.request$(identifier.chapterId)), // short-lived
            filter(() => this.nextFocus !== undefined), // meanwhile the `nextFocus` might have changed
            switchMap(() =>
                hasHighlights(this.nextFocus.chapterId) ? whenHighlightsAreRendered$ : whenChapterIsRendered$,
            ),
            map(renderedHits => ({ ...this.nextFocus, renderedHits })),
        );
    }

    private get setDebugAttributesOnHitsUpdate(): (highlights: HighlightsPerChapter) => void {
        // FYI this doesn't work as expected ...
        //     the counting is independent of hit count inside previous chapter which leads to wrong numbers in some cases
        return highlightsPerChapter => {
            highlightsPerChapter.forEach((highlights, chapterId) => {
                const chapterIndex = this.chapterIdsWithHits.findIndex(id => id === chapterId);
                const rootId = this.model.meta.root.id;
                highlights.forEach((highlight, hitIndex) => {
                    highlight.setAttribute(
                        'data-debug-hit',
                        `${chapterId}.${hitIndex}:${rootId}.${chapterIndex + hitIndex + 1}`,
                    );
                });
            });
        };
    }

    private get updateStateOnNextFocusAfterHitsAreRendered$(): (next: NextFocusWithRenderedHits) => void {
        return next => {
            let hitIndex: number;
            if (next.hitIndex >= 0) {
                // we know the position of the next hit already
                hitIndex = next.hitIndex;
            } else if (next.renderedHits === undefined) {
                // this is the case where current chapter has no hits
                this.state.set({
                    isLoading: false,
                    nextFocus: undefined,
                });
                return;
            } else {
                hitIndex = this.goForward ? 0 : next.renderedHits.length - 1; // we need to determine position first
            }

            const update: Partial<DocumentSearchState> = {
                currentPosition: {
                    chapterId: next.chapterId,
                    chapterIndex: next.chapterIndex,
                    hitIndex,
                },
                // We need to update `hasPrevious` as well because maybe we just jumped to the last chapter
                // and, now we can tell whether there are more hits in previous direction or not
                hasPrevious:
                    next.chapterIndex > 0 || // not the first chapter
                    hitIndex > 0, // not the first hit
                isLoading: false,
                nextFocus: undefined,
            };
            logger.debug(this.logPrefix, 'updateStateOnNextFocusAfterHitsAreRendered$ -> updating state', update);
            this.state.set(update);

            // also since we are in search navigation mode; we need to ensure query is set in url
            // (for consistent UX when user reloads page)
            // Why is it important here?
            // Imagine the following:
            //   - user navigates away from focused hit (e.g. with toc interaction)
            //   - user navigates back with search navigation (next button)
            //   - user reloads page => search & focus should be restored
            this.location.updateQueryParameters({ query: this.query }, { triggerResolvers: false });

            if (next.scrollIntoView) {
                const currentFocus = this.model.focus;
                const element = next.renderedHits.item(hitIndex);
                const restoreFocus: RestoreFocus = {
                    chapter: this.model.getChapter(next.chapterId),
                    offsetInPercent: 0,
                    toSearchHit: {
                        element,
                        chapterJump: !currentFocus || currentFocus.chapter.index !== next.chapterIndex,
                    },
                };

                logger.debug(
                    this.logPrefix,
                    'updateStateOnNextFocusAfterHitsAreRendered$ -> requesting `restoreFocus`',
                    restoreFocus,
                );
                this.model.actions.restoreFocus(restoreFocus);
            }
        };
    }

    private getHighlightsFor(chapterId: DocumentId): NodeListOf<HTMLElement> {
        return this.state.get('highlightsPerChapter').get(chapterId);
    }

    private initResetCurrentPositionOnNewDocument(): void {
        this.state.connect(this.model.routedDocument$, () => {
            logger.debug(this.logPrefix, 'routedDocument$ -> will reset `currentPosition`');
            return { currentPosition: undefined };
        });
    }

    private initResetStateOnRouteChange(): void {
        const relevantRouteChange$ = this.model.routedDocument$.pipe(
            // we only want changes in either the query (means different search result) or navigation to a whole different document
            distinctUntilChanged(
                (previous, current) =>
                    previous.meta.productId === current.meta?.productId && // within same product
                    previous.meta.root.id === current.meta?.root?.id && // same root
                    previous.search?.query === current.search?.query, // same query
            ),
        );

        /**
         * Search results are reloaded when:
         *  (1) query changes
         *  (2) whole document changes, e.g. on search page when user switches between documents (query stays unchanged then).
         *
         * When this happens we should reset the state completely.
         *
         * We MUST NOT reset the state if there was only a navigation within the document, e.g. interaction with toc.
         * (We might end up in remove query / search state completely, which is unexpected)
         */
        this.state.connect(relevantRouteChange$, (_, routed) => {
            const hasSearch = routed.search?.resultsFound;
            const chapterIndex = hasSearch
                ? routed.search.docIds.findIndex(id => id === routed.document.id)
                : undefined;
            const hasPrevious = hasSearch && chapterIndex > 0;
            // we always allow the next button if there are at least 2 hits ...
            // (for jumping to top again when last hit is focused and user clicks "next")
            const hasNext = hasSearch && routed.search.docIds.size > 1;
            const state: Omit<DocumentSearchState, 'currentPosition'> = {
                direction: DocumentSearchDirection.Forward,
                hasPrevious,
                hasNext,
                // search hits in DOM are unknown yet
                highlightsPerChapter: ImmutableMap(),
                isLoading: hasSearch,
                // !only multipart! => the flat document case is different and handled below
                // will ensure that very first search hit after a new search result arrived gets focused
                // but, we shouldn't set it if there is no search for consistency
                nextFocus: hasSearch
                    ? {
                          chapterId: routed.document.id,
                          chapterIndex,
                          scrollIntoView: true,
                      }
                    : undefined,
                searchResult: routed.search,
                query: routed.search?.query,
            };
            logger.debug(this.logPrefix, 'routedDocument$ -> will update internal', routed, state);
            return state;
        });
    }
}
