import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, Output, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { EcondaTarget, EcondaTargetGroup, EcondaTargetName } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { debounce, interval, MonoTypeOperatorFunction, switchMap, tap } from 'rxjs';
import { EcondaTrackingService } from '../../../../core';
import { SuggestionService } from '../../../../core/suggestion/suggestion.service';
import { SearchSuggestion } from './search-suggestion';

export const SUGGESTION_DELAY = 300;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'idr-suggestions',
    standalone: true,
    styleUrls: ['./suggestions.component.scss'],
    templateUrl: './suggestions.component.html',
    imports: [AsyncPipe],
})
export class SuggestionsComponent {
    /**
     * You need to set this in order to have tracking enabled in this component.
     * It is used as prefix/context for tracking events.
     * Its value depends on the context your component is living in.
     * For example inside:
     * - the document searchBar it should be set to `EcondaTargetGroup.DOCUMENT`
     * - the header searchBar it should be set to `EcondaTargetGroup.HEADER`
     */
    @Input({ required: true }) trackingContext: EcondaTargetGroup;

    @Output() readonly suggestionSelected = new EventEmitter<string>();

    readonly suggestions: Signal<SearchSuggestion[]>;
    query = input('');
    focused = input(false);

    focusDelayed$ = toObservable(this.focused).pipe(this.#defaultDebounce());

    constructor(
        private readonly suggestionService: SuggestionService,
        private readonly econdaTrackingService: EcondaTrackingService,
    ) {
        this.suggestions = this.suggestionService.suggestions;
        this.#initSuggestions$();
    }

    onArrowUp(): void {
        let selectedIndex: number = this.#selectedIndex;

        if (selectedIndex >= 0) {
            selectedIndex--;
        }

        logger.debug(`[SuggestionsComponent] onArrowUp -> go to suggestion`, selectedIndex);
        this.#selectByIndex(selectedIndex);
    }

    onArrowDown(): void {
        let selectedIndex: number = this.#selectedIndex;

        if (selectedIndex < this.suggestions().length - 1) {
            selectedIndex++;
        }

        logger.debug(`[SuggestionsComponent] onArrowDown -> go to suggestion`, selectedIndex);
        this.#selectByIndex(selectedIndex);
    }

    /**
     * Will be called when user selects a suggestion by clicking on it or by selecting using arrow keys + [enter].
     *
     * @param suggestion optional when selected using [enter]
     */
    selectSuggestion(suggestion?: string): void {
        if (!suggestion) {
            return;
        }
        this.suggestionSelected.next(suggestion);
        this.#markSelected(suggestion);

        if (this.trackingContext) {
            const target: EcondaTarget = new EcondaTarget(
                this.trackingContext,
                `${EcondaTargetName.SearchSuggestion}/${suggestion}`,
            );
            this.econdaTrackingService.trackTarget(target);
        }
    }

    #markSelected(searchTerm: string) {
        const suggestions = this.suggestions();

        const itemToSelect = suggestions.find(item => item.searchTerm === searchTerm);
        itemToSelect.selected = true;

        this.#updateSuggestionsState(suggestions);
    }

    #updateSuggestionsState(suggestions: SearchSuggestion[]) {
        this.suggestionService.setSuggestionsState(suggestions);
    }

    get hasSelection(): boolean {
        return !!this.#selected;
    }

    get #selectedIndex(): number {
        return this.suggestions().findIndex((suggestion: SearchSuggestion) => suggestion.selected);
    }

    get #selected(): SearchSuggestion {
        return this.suggestions().find((suggestion: SearchSuggestion) => suggestion.selected);
    }

    get selectedSearchTerm() {
        return this.#selected?.searchTerm;
    }

    #selectByIndex(selectedIndex: number): void {
        const resetAndMarkSelected = (suggestions: SearchSuggestion[]) => {
            suggestions.forEach((item: SearchSuggestion) => (item.selected = false));
            if (selectedIndex >= 0 && selectedIndex < suggestions.length) {
                suggestions[selectedIndex].selected = true;
            }
            return suggestions;
        };

        const updatedSuggestions = resetAndMarkSelected(this.suggestions());
        this.#updateSuggestionsState(updatedSuggestions);
    }

    #initSuggestions$() {
        const query$ = toObservable(this.query).pipe(this.#defaultDebounce());
        const queryUpdate$ = query$.pipe(
            switchMap((query: string) => this.suggestionService.getSuggestions$(query)),
            tap(suggestions => this.#updateSuggestionsState(suggestions)),
        );
        queryUpdate$.subscribe();
    }

    #defaultDebounce<T>(): MonoTypeOperatorFunction<T> {
        return debounce<T>(() => interval(SUGGESTION_DELAY));
    }
}
