import { Injectable } from '@angular/core';
import { BauplanItem, BauplanItemType, DocumentId, EcondaPath } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Sitemap } from '../model';
import { EcondaTrackingService } from './econda-tracking.service';

/**
 * This is used to track page changes in "bauplan view".
 * In "bauplan view" when a new id / column is requested, we don't know how which columns we need to open automatically.
 * We only have a logic in place that decides whether or not another column shall be opened automatically.
 * Also the bauplan is a tree (in theory endless). So maybe we are done with opening just the requested column.
 * But most cases involve multiple additional columns that need to be opened. Each column is requested individually and contains only
 * metadata about itself and the "next" level...
 *
 * So whenever we visit a new column {@link queue} gets called.
 * And whenever we encounter a column where no more column gets opened (that means navigation is done), we call {@link release}.
 */
@Injectable({ providedIn: 'root' })
export class BauplanTrackingService {
    /**
     * Holds the {@link DocumentId} of the preview that was successfully loaded by the `DocumentPreviewModule`.
     */
    private readonly availablePreview$: BehaviorSubject<DocumentId> = new BehaviorSubject(undefined);

    /**
     * Holds the {@link DocumentId} of the preview that is expected to be shown inside bauplan.
     */
    private readonly waitForPreview$: BehaviorSubject<DocumentId> = new BehaviorSubject(undefined);

    private _pending: EcondaPath;

    constructor(private readonly econdaTrackingService: EcondaTrackingService) {}

    private get pending(): EcondaPath {
        return this._pending;
    }

    private set pending(pending: EcondaPath) {
        logger.debug(`[BauplanTrackingService] pending ->`, pending);
        this._pending = pending;
    }

    public set preview(preview: DocumentId) {
        logger.debug('[BauplanTrackingService] preview ->', preview);
        this.availablePreview$.next(preview);
    }

    /**
     * Sets current path based on {@param sitemap} and {@param bauplan}.
     * It overwrites whatever was stored before.
     * As soon as you call {@link release} this will be used for tracking current state of "bauplan view".
     */
    public queue(sitemap: Sitemap, bauplan: BauplanItem): void {
        if (
            bauplan.linkType === BauplanItemType.LEXICON_LINK ||
            bauplan.children[0]?.linkType === BauplanItemType.LEXICON_LINK
        ) {
            // the lexicon case is handled differently
            logger.warn(`[BauplanTrackingService] queue IGNORED since we got a lexicon`, sitemap, bauplan);
            return;
        }

        logger.debug(`[BauplanTrackingService] queue ->`, sitemap, bauplan);
        switch (bauplan.linkType) {
            case BauplanItemType.BAUPLAN_LINK:
            case BauplanItemType.FILTER_LINK:
                this.pending = sitemap.map(item => (item.label as string).replace('/', '|')).join('/');
                break;
            case BauplanItemType.DOCUMENT_LINK:
                this.pending = sitemap
                    .map(item => (item.label as string).replace('/', '|'))
                    .slice(0, -1)
                    .join('/');
                this.waitForPreview$.next(bauplan.id);
                logger.debug(`[BauplanTrackingService] preview involved ->`, bauplan.id);
                break;
        }
    }

    /**
     * Will track current state with {@link EcondaTrackingService}.
     */
    public release(): void {
        if (!this.pending) {
            logger.warn(`[BauplanTrackingService] release. Nothing queued. Will ignore this call.`);
            return;
        }

        // when bauplan shows a preview as well we don't know what data is available first
        // so we need to wait here and ensure that we wait for the correct preview
        const waitsForPreview: DocumentId | undefined = this.waitForPreview$.value;
        if (waitsForPreview !== undefined) {
            logger.debug(`[BauplanTrackingService] release. Need to wait for preview ->`, waitsForPreview);
            this.availablePreview$
                .pipe(
                    filter(id => id === waitsForPreview),
                    take(1),
                    tap(() => this.trackPageChange()),
                )
                .subscribe();
            return;
        }

        this.trackPageChange();
    }

    private trackPageChange(): void {
        logger.debug(`[BauplanTrackingService] trackPageChange ->`, this.pending);
        this.econdaTrackingService.trackPageChange(this.pending);
        this.reset();
    }

    private reset(): void {
        this._pending = undefined;
        this.waitForPreview$.next(undefined);
    }
}
