import { inject, InjectionToken } from '@angular/core';
import { LegacyDocumentService } from '@idr/ui/crs-facade';
import { DocumentService } from '@idr/ui/document';

// Kept this here because when it was in the @idr/ui/document lib it created a circular dependency
// because @idr/ui/crs-facade imports things from @idr/ui/document and this would need to import the LegacyDocumentService
// from @idr/ui/crs-facade

export const DOCUMENT_SERVICE: InjectionToken<DocumentService> = new InjectionToken('DOCUMENT_SERVICE', {
    providedIn: 'root',
    factory: () => inject(LegacyDocumentService),
});
