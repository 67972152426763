import { enableProdMode } from '@angular/core';
import { toggleNormVersion } from '@idr/ui/shared';
import { environment } from './environments/environment';
import { loadSettingsAndInitApplication, patchEncodeUriComponent } from './init';

patchEncodeUriComponent();

// needed for norm specific elements in crs documents
window['toggleNormVersion'] = toggleNormVersion;

if (environment.production) {
    enableProdMode();
}

loadSettingsAndInitApplication(environment);
