import { AsyncPipe, NgForOf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { NodeDTO } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ProductLogoComponent } from '@idr/ui/header';
import { ActiveProduct, LetDirective, RouterEvents } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { NavigationService } from '../../../core';
import { CrsSearchService } from '../../../core/crs/service/crs-search.service';
import { SearchTrackingService } from '../../../core/tracking';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HeaderSearchInputComponent } from './header-search-input/header-search-input.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        NgForOf,

        MatSelectModule,

        HeaderMenuComponent,
        HeaderSearchInputComponent,
        LetDirective,
        ProductLogoComponent,
    ],
    selector: 'idr-header',
    standalone: true,
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements AfterViewInit {
    @ViewChild('border') public border: ElementRef;

    @ViewChild(MatSelect) public categorySelect: MatSelect;

    @Input() public showProductLogo = false;

    public readonly searchableCategories$: Observable<NodeDTO[]>;

    private readonly preselectedCategoryChange$: Observable<string>;

    private readonly effects = rxEffects();

    private readonly logPrefix = '[HeaderComponent]';

    constructor(
        private readonly renderer: Renderer2,
        // own
        activeProduct: ActiveProduct,
        legacySearch: CrsSearchService,
        private readonly navigationService: NavigationService,
        routerEvents: RouterEvents,
        private readonly searchTrackingService: SearchTrackingService,
    ) {
        this.preselectedCategoryChange$ = routerEvents.routeParams$.pipe(
            map(params => ({
                selected: this.categorySelect?.value?.path ?? undefined,
                // we need to map undefined to empty string so that our distinctUntilChanged right below works as intended
                // (empty string matches `path` of "All documents" category)
                requested: params.path ?? '',
            })),
            tap(path => logger.debug(this.logPrefix, 'preselectedCategory$ ->', path)),
            // in this particular case we aren't interested in distinct values of the `queryParams.path` but rather in distinct value compared to current selection
            distinctUntilChanged((previous, current) => current.selected === current.requested),
            map(path => path.requested),
            tap(path => logger.debug(this.logPrefix, 'preselectedCategory$ ->', path)),
            shareReplay(1),
        );

        this.searchableCategories$ = activeProduct.product$.pipe(
            filter(Boolean),
            switchMap(() => legacySearch.getSearchableCategories$()),
            tap(categories => logger.debug(this.logPrefix, 'searchableCategories$ ->', categories)),
            shareReplay(1),
        );
    }

    public ngAfterViewInit(): void {
        this.effects.register(
            fromEvent(window, 'resize').pipe(
                debounceTime(100),
                filter(() => this.categorySelect?.panelOpen),
            ),
            () => this.categorySelect.close(),
        );

        // For the sake of consistency, we need to keep the category selection in sync with the actual searched category
        // It's especially important in the case where the user searched in a category that didn't result in any hits.
        // (the app selects "All documents" instead so we can show at least some result)
        this.effects.register(
            combineLatest([this.preselectedCategoryChange$, this.searchableCategories$]),
            ([path, categories]) => {
                const selectedCategory = categories.find(category => category.path === path) ?? categories[0];
                logger.debug(
                    this.logPrefix,
                    'preselectedCategory$ &  searchableCategories$ -> will update category select',
                    path,
                    categories,
                    selectedCategory,
                );
                this.categorySelect.value = selectedCategory ?? categories[0];
            },
        );
    }

    public async onSubmitCalled(query: string, category: NodeDTO): Promise<void> {
        logger.debug(this.logPrefix, 'onSubmitCalled ->', query, category);
        if (query?.trim().length > 0) {
            this.searchTrackingService.preselectedCluster = category.title;
            await this.navigationService.toSearchPage(query, category.path, category.title);
        }
    }

    /**
     * Why can't we just assign a variable and use it directly in the template like this:
     * <div [class.shown]="categorySelectOpened"></div>
     * This works fine but can cause the NG0100 error (ExpressionChangedAfterItHasBeenCheckedError)
     * when the select box is quickly opened and  closed. This sometimes happens in cypress tests.
     */
    public set categorySelectOpened(categorySelectOpened: boolean) {
        if (categorySelectOpened) {
            this.renderer.addClass(this.border.nativeElement, 'shown');
            return;
        }

        this.renderer.removeClass(this.border.nativeElement, 'shown');
    }
}
