import { INTERNAL_EMAIL_DOMAINS } from './internal-email-domains';

/**
 * checks if email has haufe-internal domain name inside
 * @see {@link INTERNAL_EMAIL_DOMAINS}
 * @param email
 */
export const isHaufeInternalEmail = (email: string): boolean => {
    const emailDomain = email?.split('@')[1];
    if (!emailDomain) return false;
    return INTERNAL_EMAIL_DOMAINS.includes(emailDomain);
};
