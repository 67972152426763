import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveProduct, LocationService, MessageService, ProductService, SettingsService } from '@idr/ui/shared';
import { RouterEventsLogic } from '../../app/app/router-events-logic';
import { addEconda, AppStateService, ConsentTrackingService, NavigationService } from '../../app/core';
import { EcondaService } from '../../app/core/tracking/econda.service';
import { GtmTrackingService } from '../../app/core/tracking/gtm-tracking.service';
import { showErrorPageForNoProducts } from './show-error-page-for-no-products';

export const APP_INITIALIZER_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: (router, appState, navService, activeProduct, messages) => () =>
            new RouterEventsLogic(router, appState, navService, activeProduct, messages),
        deps: [Router, AppStateService, NavigationService, ActiveProduct, MessageService],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => Promise.resolve(),
        deps: [ConsentTrackingService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: addEconda,
        deps: [DOCUMENT, SettingsService, LocationService, EcondaService, ConsentTrackingService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        multi: true,
        // This is a way to initialize the GTM tracking service without injecting it to any component
        deps: [GtmTrackingService],
        // The factory should resolve the promise, otherwise the application will wait for the initialization to finish.
        useFactory: () => () => Promise.resolve(),
    },
    {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [Router, ProductService],
        useFactory: showErrorPageForNoProducts,
    },
];
