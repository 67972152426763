import { Inject, inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Product } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { List as ImmutableList } from 'immutable';
import { NavigationService } from '../navigation/navigation.service';
import { LAST_ACTIVE_PRODUCT_ID } from './last-active-product';

@Injectable({ providedIn: 'root' })
export class ProductRedirectionService {
    private readonly logPrefix = '[ProductRedirectionService]';

    constructor(
        @Inject(LAST_ACTIVE_PRODUCT_ID) public readonly lastActiveProductId = inject(LAST_ACTIVE_PRODUCT_ID),
        private readonly activeProduct: ActiveProduct,
        private readonly navigationService: NavigationService,
    ) {}

    public activateProduct(product: Product, urlPathParts: string[], queryParams: Params): void {
        this.activeProduct.product = product;

        urlPathParts[0] = product.id;
        logger.info(
            this.logPrefix,
            'activateProduct -> Navigate to product',
            product.id,
            'urlPathParts',
            urlPathParts,
            'queryParams',
            queryParams,
        );
        this.navigationService.navigateToIfNoRedirectIsPending(urlPathParts, { queryParams, replaceUrl: true });
    }

    public redirectToLastActiveOrDefaultProduct(
        candidates: ImmutableList<Product>,
        urlPathParts: string[],
        queryParams: Params,
    ): void {
        if (candidates.size === 0) {
            logger.warn(this.logPrefix, 'redirectToDefaultProduct -> No products available. Will fallback to error');
            throw new Error('No products available');
        }

        // we prefer to open the last opened product ALWAYS if possible
        const lastActiveProduct = candidates.find(product => product.id === this.lastActiveProductId);
        if (lastActiveProduct) {
            logger.debug(
                this.logPrefix,
                'redirectToDefaultProduct -> considering last opened product',
                this.lastActiveProductId,
            );
            this.activateProduct(lastActiveProduct, urlPathParts, queryParams);
            return;
        }

        logger.debug(this.logPrefix, "redirectToDefaultProduct -> couldn't find last opened product");
        this.activateProduct(candidates.first(), urlPathParts, queryParams);
    }
}
