import { inject, InjectionToken } from '@angular/core';
import { DocumentId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { DEBUG_SETTINGS } from '@idr/ui/shared';
import { combineLatest, debounceTime, distinctUntilChanged, EMPTY, map, Observable, shareReplay, tap } from 'rxjs';
import { immutableDistinctUntilChanged } from '../../../utils/rxjs';
import { distinctUntilChangedForJson } from '../distinct-until-changed-for-json';
import { distinctUntilChangedForPending } from '../distinct-until-changed-for-pending';
import { DocumentModel } from '../document-model/document-model';
import { Debug, DebugChapters, DebugFocus, DebugSearch } from '../types';

const deriveFromDocumentState = () => {
    const model = inject(DocumentModel);

    const chapters$: Observable<DebugChapters> = model.state.select('chapters').pipe(
        immutableDistinctUntilChanged(),
        map(chapters => {
            const total = chapters.size;
            let fake = 0;
            let real = 0;
            const realChapterIds: DocumentId[] = [];
            chapters.forEach(chapter => {
                if (chapter.isDummy) {
                    fake++;
                } else {
                    real++;
                    realChapterIds.push(chapter.id);
                }
            });
            return {
                fake,
                real,
                total,
                realChapterIds,
            };
        }),
    );

    const focus$: Observable<DebugFocus> = model.state.select('focus').pipe(
        distinctUntilChanged(
            (previous, current) =>
                previous?.anchor === current?.anchor &&
                previous?.chapter.equals(current?.chapter) &&
                previous?.offsetInPercent === current?.offsetInPercent,
        ),
        map(focus => ({
            id: focus.chapter.id,
            index: focus.chapter.index,
            anchor: focus.anchor,
            offsetInPercent: focus.offsetInPercent,
        })),
    );

    const pending$ = model.state.select('pending').pipe(distinctUntilChangedForPending());

    const requestQueue$ = model.state.select('requestQueue').pipe(immutableDistinctUntilChanged());

    const search$: Observable<DebugSearch> = combineLatest([
        model.state.select('searchCache').pipe(immutableDistinctUntilChanged()),
        model.state.select('chapters').pipe(immutableDistinctUntilChanged()),
    ]).pipe(
        map(([searchCache, chapters]) => ({
            cacheSize: searchCache.size,
            chapters,
            relatedIndex: searchCache.get(model.query),
            query: model.query,
        })),
        map(({ query, cacheSize, chapters, relatedIndex }) => ({
            cacheSize,
            chapters: {
                involved: relatedIndex?.size ?? 0,
                alreadyLoaded: chapters.filter(chapter => !chapter.isDummy && relatedIndex?.has(chapter.id)).size,
            },
            query,
        })),
    );

    const ui$ = model.state.select('ui').pipe(distinctUntilChangedForJson());

    return combineLatest([
        model.state.select('backendError'),
        model.state.select('initialLoading'),
        chapters$,
        focus$,
        pending$,
        requestQueue$,
        search$,
        ui$,
    ]).pipe(
        debounceTime(500),
        map(([backendError, initialLoading, chapters, focus, pending, requestQueue, search, ui]) => ({
            backendError,
            chapters,
            focus,
            initialLoading,
            pending,
            requestQueue,
            search,
            ui,
        })),
        tap(debug => logger.debug('[DEBUGGED_DOCUMENT_STATE] value$ ->', debug)),
        shareReplay(1),
    );
};

export const DEBUGGED_DOCUMENT_STATE: InjectionToken<Observable<Debug>> = new InjectionToken('DEBUGGED_DOCUMENT_STATE');

export const DEBUGGED_DOCUMENT_STATE_PROVIDER = {
    provide: DEBUGGED_DOCUMENT_STATE,
    useFactory: () => {
        const settings = inject(DEBUG_SETTINGS);
        if (settings.document) {
            return deriveFromDocumentState();
        }
        return EMPTY;
    },
};
