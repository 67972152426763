import { DocumentId } from '@idr/shared/model';
import { DocumentMeta, RoutedDocument } from '@idr/ui/document';
import { stripHtml } from '@idr/ui/shared';
import { GtmCurrentDocumentData } from '@idr/ui/tracking';
import { EmptySearchResult, HitlistSearchResult } from '../../search-page/model/search-result';
import { IDeskTopicPage } from '../../topic-page';

/**
 * @returns a nice document specific url containing the document id, its title and also type. This is done to
 *          provide nice reports in econda. The result almost looks like a "breadcrumb".
 */
export const createDocumentContentUrl = (url: string, docId: DocumentId, documentMeta: DocumentMeta): string => {
    const docType: string = documentMeta.classification.description;
    const docTitle: string = documentMeta.title.replace(/\//g, '|');
    return `${url}/${docType}/[${docId}] ${docTitle}`;
};

export const createTopicPageContentUrl = (url: string, topicPage: IDeskTopicPage): string =>
    `${url}/Themenseite/[${topicPage.docId}] ${stripHtml(topicPage.title)}`;

export const createGtmCurrentDocumentData = (documentFromRoute: RoutedDocument): GtmCurrentDocumentData | undefined => {
    if (!documentFromRoute) {
        return undefined;
    }
    const docMeta = documentFromRoute.meta;

    const rootId = docMeta.root.id;
    const rootTitle = docMeta.root.title;
    const id = documentFromRoute.document?.id;

    const rootDocumentData: GtmCurrentDocumentData = {
        rootId,
        rootTitle,
        docDisplayType: documentFromRoute.meta?.classification?.description,
        docSubType: documentFromRoute.meta?.classification?.subType,
        docType: documentFromRoute.meta?.classification?.type,
    };

    if (id === rootId) {
        return rootDocumentData;
    }
    return {
        ...rootDocumentData,
        id,
        title: documentFromRoute.document?.title,
    };
};

export const distinctSearchResult = (
    prev: HitlistSearchResult | EmptySearchResult,
    curr: HitlistSearchResult | EmptySearchResult,
) => {
    const isSameSearchTerm = prev.searchTerm == curr.searchTerm;
    if ('newSearch' in prev && 'newSearch' in curr) {
        const isNewSearch = prev.newSearch == curr.newSearch;
        return isSameSearchTerm || isNewSearch;
    }
    return isSameSearchTerm;
};
