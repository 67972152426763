import { DOCUMENT } from '@angular/common';
import { EnvironmentInjector, InjectionToken, runInInjectionContext } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { WINDOW } from '@idr/ui/shared';

const FALLBACK_FONT_SIZE = 14;

export type GetFontSizeFn = (target?: HTMLElement) => number;

export const GET_FONT_SIZE = new InjectionToken<GetFontSizeFn>('Gives you `getFontSize` function');

const getFontSize = (injector: EnvironmentInjector, target?: HTMLElement): number =>
    runInInjectionContext(injector, () => {
        const window = injector.get(WINDOW);
        target = target ?? injector.get(DOCUMENT).body;
        const fontSizeProperty = window.getComputedStyle(target).getPropertyValue('font-size');
        logger.debug('getFontSize -> got', target, { fontSizeProperty });
        if (!fontSizeProperty) {
            return FALLBACK_FONT_SIZE;
        }
        return Number(fontSizeProperty.match(/\d+/)[0]);
    });

export const GET_FONT_SIZE_PROVIDER = {
    provide: GET_FONT_SIZE,
    deps: [EnvironmentInjector],
    useFactory: (injector: EnvironmentInjector) => (target?: HTMLElement) => getFontSize(injector, target),
};
