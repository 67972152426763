import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DebugSettings, isHaufeInternalEmail, Profile, UserId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { filter, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { AuthHttpInterceptor } from '../http/auth-http-interceptor.service';
import { DEBUG_SETTINGS, SettingsService } from '../settings';

const UUID_OFFSET = '823bf52c-78b7-49a1-80ae-5dcec3f8e3ef';

// regular uuid pattern | mocked for e2e
const UUID_PATTERN = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[0-9a-f]{4}-[0-9a-f]{4})/;

@Injectable({ providedIn: 'root' })
export class ProfileService {
    /**
     * The encoded user id is used when we need to pass the user id for tracking purposes, but we don't want to expose the real user id.
     */
    readonly encodedUserId$: Observable<string>;
    /**
     * Admin users shall be able to use the myDesk configuration UI.
     */
    readonly isAdmin$: Observable<boolean>;
    /**
     * Anonymous is a user that is logged in via partner flow, but cannot be matched to a user known in our license system.
     */
    readonly isAnonymous$: Observable<boolean>;

    readonly profile$: Observable<Profile | undefined>;
    readonly userId$: Observable<UserId>;

    readonly #logPrefix = '[ProfileService]';
    readonly isEmailHaufeInternal$: Observable<boolean>;

    constructor(
        private readonly http: HttpClient,
        private readonly settings: SettingsService,
        @Inject(DEBUG_SETTINGS) debug: DebugSettings,
    ) {
        this.profile$ = this.#requestProfile$();

        this.isAdmin$ = this.profile$.pipe(map(profile => profile?.admin === true || debug.user_is_admin === true));

        this.isEmailHaufeInternal$ = this.profile$.pipe(
            filter(Boolean),
            map(profile => isHaufeInternalEmail(profile.email)),
            shareReplay(1),
        );

        this.userId$ = this.profile$.pipe(
            map(profile => profile?.HMGUSERID as string),
            shareReplay(1),
        );

        this.isAnonymous$ = this.userId$.pipe(map(it => !it));

        /**
         * The logic for the whole encoding of the user id is taken from
         * https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-cl-gwt-frontend/src/main/java/de/haufe/aurora/gwt/survey/client/presenter/SurveyPresenter.java
         */
        this.encodedUserId$ = this.userId$.pipe(
            map(userId => this.#shiftUuid(userId)),
            shareReplay(1),
        );
    }

    #requestProfile$(): Observable<Profile | undefined> {
        return this.http
            .get<Profile>(`${this.settings.bffHost}/api/profile`, { headers: AuthHttpInterceptor.optInAuthHeaders })
            .pipe(
                tap(profile => logger.debug(this.#logPrefix, '#requestProfile$ ->', profile)),
                catchError(err => {
                    logger.error(this.#logPrefix, "#requestProfile$ -> Couldn't get profile", err);
                    return of(undefined);
                }),
                shareReplay(1),
            );
    }

    #shiftUuid(uuid: string): string {
        if (!UUID_PATTERN.test(uuid)) {
            throw new Error(`uuid does not match the uuid pattern`);
        }

        let shiftedUuid = '';
        for (let i = 0; i < uuid.length; i++) {
            if (uuid[i] === '-') {
                shiftedUuid += '-';
            } else {
                shiftedUuid += this.#shiftChar(uuid[i], UUID_OFFSET[i]);
            }
        }

        return shiftedUuid;
    }

    /**
     * Implementation taken from iDesk2 to make sure that we didn't change the result.
     * https://gitlab.haufedev.systems/aurora/backend/aurora.core/-/blob/master/aua-cl-gwt-frontend/src/main/java/de/haufe/aurora/gwt/survey/client/presenter/SurveyPresenter.java#L132
     *
     * !IMPORTANT! The implementation is slightly different from the one in iDesk2, but it results in the same result. (see unit tests)
     * That is probably due to the different way that js makes the calculation compared to Java.
     */
    #shiftChar(char: string, offset: string): string {
        const iHex = parseInt(char, 16);
        const iOffset = parseInt(offset, 16);
        let iChar = iHex - iOffset;
        if (iChar < 0) {
            const div = iChar / 16;
            iChar = (div + 1) * 16;
        }
        return iChar.toString(16);
    }
}
