import { Directive } from '@angular/core';
import { logger } from '@idr/shared/utils';
import { CommonNavigationService, MessageService } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { filter } from 'rxjs/operators';
import { AppStateService } from '../../../core';

/**
 * This directive will redirect to StartPage if message
 * is published on the messageService and the whole page is empty.
 * This logic was originally in the AppComponent.
 */
@Directive({
    selector: '[idrMessage]',
    standalone: true,
})
export class MessageDirective {
    constructor(appState: AppStateService, messageService: MessageService, commonNavigation: CommonNavigationService) {
        rxEffects(({ register }) =>
            register(
                messageService.message$.pipe(
                    filter(Boolean),
                    filter(() => appState.isPageEmpty),
                ),
                () => {
                    logger.debug(
                        `[MessageDirective] Since there was posted a message and app isn't loaded yet. Fallback => load startpage.`,
                    );
                    commonNavigation.toStartpage();
                },
            ),
        );
    }
}
