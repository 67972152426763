import { EnvironmentInjector } from '@angular/core';
import { createIDeskDocument, IDeskDocument } from '@idr/ui/document';
import { convertDocSizeToHeightEmString } from '../../../document/utils/doc-size-conversions';
import { IDeskDocumentDummy } from '../models';

/**
 * Used as placeholder for a document that is not yet loaded.
 */
export const createIDeskDocumentDummy = (
    injector: EnvironmentInjector,
    mandatory: Pick<IDeskDocument, 'id' | 'index' | 'docSize' | 'title'>,
    context: {
        readonly fontSize: number;
        readonly width: number;
    },
): IDeskDocumentDummy => {
    const trackBy = `dummy-${mandatory.id}`;
    const asDocument = createIDeskDocument(
        injector,
        {
            ...mandatory,
            content: '',
            debugLinks: undefined,
            longTitle: undefined,
        },
        trackBy,
    );
    const { fontSize, width } = context;
    const docSize = asDocument.docSize;
    const height = convertDocSizeToHeightEmString(docSize, { fontSize, width });
    return {
        id: asDocument.id,
        index: asDocument.index,
        isDummy: true,
        height,
        docSize,
        title: asDocument.title,
        trackBy,
        getElement: asDocument.getElement,
        hashCode: asDocument.hashCode,
        equals: asDocument.equals,
    };
};
