import { Params } from '@angular/router';
import { BauplanId, BauplanViewType, NodeDTO } from '@idr/shared/model';

export type BauplanViewPath = BauplanId | 'lexicon' | 'filter' | { readonly sel: BauplanId };

export class BauplanView {
    readonly bauplanId: BauplanId;
    readonly type: BauplanViewType;
    readonly queryParams: Params;

    private constructor(bauplanId: string, type: BauplanViewType, queryParams: Params) {
        this.bauplanId = bauplanId;
        this.type = type;
        this.queryParams = queryParams;
    }

    static fromBauplanMeta(bauplanId: BauplanId, nodeDTO: NodeDTO): BauplanView {
        if (!nodeDTO.leavesonly) {
            return new BauplanView(bauplanId, BauplanViewType.Default, {});
        }
        switch (nodeDTO.subnodes.docMetatype) {
            case 'BERS':
            case 'BIB':
            case 'ENTSCH':
            case 'ENTSCH_EUGH':
            case 'VA':
                return new BauplanView(bauplanId, BauplanViewType.Filter, { sort: 'datum-' });
            case 'VERF':
                return new BauplanView(bauplanId, BauplanViewType.Filter, { sort: 'vor_datum-' });
            default:
                if (nodeDTO.subnodes.issorted && nodeDTO.subnodes.numnodes >= 51) {
                    return new BauplanView(bauplanId, BauplanViewType.Lexicon, {});
                }
                return new BauplanView(bauplanId, BauplanViewType.Default, {});
        }
    }

    get paths(): BauplanViewPath[] {
        const paths: BauplanViewPath[] = [];
        if (this.type === BauplanViewType.Lexicon) {
            paths.push({ sel: this.bauplanId });
            paths.push('lexicon');
        } else if (this.type === BauplanViewType.Filter) {
            paths.push({ sel: this.bauplanId });
            paths.push('filter');
        }
        paths.push(this.bauplanId);
        return paths;
    }

    getQueryParams(paths: BauplanViewPath[]): Params {
        // check if bauplanId is last one of path => return queryParams for view
        return paths[paths.length - 1] === this.bauplanId ? this.queryParams : {};
    }
}

export interface BauplanViewLayout {
    readonly nested: boolean;
    readonly type: BauplanViewType;
}
