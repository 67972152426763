import { ChangeDetectionStrategy, Component, Inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { logger } from '@idr/shared/utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DraftBannerComponent, IN_DRAFT_MODE } from '@idr/ui/config';
import { LoadingWheelComponent } from '@idr/ui/shared';
import { RxIf } from '@rx-angular/template/if';
import { RxPush } from '@rx-angular/template/push';
import { Observable } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { AppStateService } from '../../core';
import { EnvironmentDirective } from '../directives/environment/environment.directive';
import { BrandingDirective } from './directives/branding.directive';
import { DebugGtmIdsDirective } from './directives/debug-gtm-ids.directive';
import { MessageDirective } from './directives/message.directive';
import { OfflineMessageDirective } from './directives/offline-message.directive';
import { TabKeyDirective } from './directives/tab-key.directive';
import { UserFeedbackDirective } from './directives/user-feedback.directive';
import { VisitCountDirective } from './directives/visit-count.directive';
import { NpsDirective } from './nps/nps.directive';
import { UsersnapComponent } from './usersnap/usersnap.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        // The `[context]` attribute is used in our global styling, e.g. for /config specific common styling
        '[attr.context]': 'routedContext()', // eslint-disable-line @typescript-eslint/naming-convention
        // We need the `draftMode` class for a slightly different layout ...
        // That is because the draft banner that is shown needs some space above the whole app
        '[class.draftMode]': 'inDraftMode()', // eslint-disable-line @typescript-eslint/naming-convention
    },
    imports: [
        RouterOutlet,

        RxIf,
        RxPush,

        BrandingDirective,
        EnvironmentDirective,
        MessageDirective,
        NpsDirective,
        OfflineMessageDirective,
        TabKeyDirective,
        UserFeedbackDirective,
        VisitCountDirective,
        DebugGtmIdsDirective,

        UsersnapComponent,
        LoadingWheelComponent,
        DraftBannerComponent,
    ],
    selector: 'idr-app',
    standalone: true,
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent {
    readonly offline: boolean;

    readonly busy$: Observable<boolean>;

    readonly loading$: Observable<boolean>;

    readonly routedContext: Signal<string>;

    constructor(
        @Inject(IN_DRAFT_MODE) public readonly inDraftMode: Signal<boolean>,
        router: Router,
        appState: AppStateService,
    ) {
        this.loading$ = appState.loading$;

        this.busy$ = appState.busy$.pipe(
            // the `delay(0)` prevents `AppComponent` from throwing a `ExpressionChangedAfterItHasBeenCheckedError`
            // while executing the change-detection it might happen that in between the `_busy$` subject got its new value => !problem!
            delay(0),
            tap(busy => logger.debug(`[AppComponent] busy$ -> ${busy}`)),
        );

        this.routedContext = toSignal(
            router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url.split('/')?.[1].split('?')[0]),
            ),
        );
    }
}
