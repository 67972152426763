import { computed, inject, InjectionToken, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DEFAULT_CONFIGURATION, IS_CONFIGURED, IS_NOT_CONFIGURED, ProductConfiguration } from '@idr/model/config';
import { BrandId } from '@idr/shared/model';
import { logger } from '@idr/shared/utils';
import { ActiveProduct } from '@idr/ui/shared';
import { filter, of, switchMap } from 'rxjs';
import { DRAFT_MODE_IS_REQUESTED } from './draft-mode-is-requested.token';
import { ProductConfigurationService } from './product-configuration.service';

/**
 * It can be undefined because of the natural behavior of signals.
 * A signal has always an initial value.
 * But, when creating it we can't know the initial value yet.
 */
export type CurrentProductConfiguration = ProductConfiguration | undefined;

/**
 * An injection token that returns the product configuration for the current active product.
 *
 * Be aware this is the configuration inside our new myDesk product configuration system.
 * It has nothing to do with the old legacy iDesk product configuration delivered via iConfig, Productionline & a legacy Java µ-service in our infrastructure.
 *
 * You get `undefined` as initial value.
 * As soon as a product could be resolved, you'll get a valid instance of {@link ProductConfiguration}.
 */
export const CURRENT_PRODUCT_CONFIGURATION_TOKEN: InjectionToken<Signal<CurrentProductConfiguration>> =
    new InjectionToken('CURRENT_PRODUCT_CONFIGURATION_TOKEN', {
        providedIn: 'root',
        factory: () => {
            // we the index for all configurations first
            const service = inject(ProductConfigurationService);
            // we need the active product because we only want to return the configuration for the active product
            const activeProduct = inject(ActiveProduct);
            const draftIsRequested$ = toObservable(inject(DRAFT_MODE_IS_REQUESTED));

            const draftConfig = toSignal(
                draftIsRequested$.pipe(
                    switchMap(draftIsRequested =>
                        draftIsRequested
                            ? activeProduct.product$.pipe(
                                  filter(Boolean),
                                  switchMap(product => service.getDraft(product.id)),
                              )
                            : of(undefined),
                    ),
                ),
            );

            return computed(() => {
                let config: ProductConfiguration | undefined = draftConfig();
                if (config) {
                    logger.debug('CURRENT_PRODUCT_CONFIGURATION_TOKEN found draft ->', config);
                    return config; // we are in draft mode & can directly return the configuration
                }

                const resolvedConfigurations = service.allConfigurations();
                // this is the initial value (part 1) ... we don't have anything from backend yet
                if (!resolvedConfigurations) {
                    logger.warn('CURRENT_PRODUCT_CONFIGURATION_TOKEN no configurations yet ->', undefined);
                    return undefined;
                }

                const product = activeProduct.productSignal();
                // this is the initial value (part 2) ... we can't tell which product it is...
                // so we have to return undefined :(
                if (!product) {
                    logger.warn('CURRENT_PRODUCT_CONFIGURATION_TOKEN no product yet ->', undefined);
                    return undefined;
                }

                config = resolvedConfigurations.get(product.id);
                if (config?.state === IS_CONFIGURED) {
                    logger.debug('CURRENT_PRODUCT_CONFIGURATION_TOKEN found for', product.id, '->', config);
                    return config;
                }

                // FIXME remove this workaround as soon as all custom brands are configured & we can use the system fallback
                //       (the system fallback is Haufe)
                const fallback: ProductConfiguration = {
                    productId: product.id,
                    group: {
                        ...(config?.group ?? {}),
                        ...DEFAULT_CONFIGURATION,
                        // brand is only in theory undefined here; it won't happen in production
                        brandId: product.brand?.id as BrandId,
                    },
                    state: IS_NOT_CONFIGURED,
                };
                logger.warn('CURRENT_PRODUCT_CONFIGURATION_TOKEN using fallback for', product.id, '->', fallback);
                return fallback;
            });
        },
    });
