import { Directive } from '@angular/core';
import { UserSettingsService } from '@idr/ui/shared';
import { rxEffects } from '@rx-angular/state/effects';
import { switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppStateService } from '../../../core';

@Directive({
    selector: '[idrVisitCount]',
    standalone: true,
})
export class VisitCountDirective {
    constructor(userSettingsService: UserSettingsService, appState: AppStateService) {
        rxEffects(({ register }) =>
            register(
                userSettingsService.settings$.pipe(
                    first(),
                    switchMap(userSettings => {
                        if (userSettings?.firstVisitDate) {
                            return [userSettings];
                        }

                        return userSettingsService
                            .updateSettings$({ firstVisitDate: Date.now() })
                            .pipe(map(() => userSettings));
                    }),
                    map(userSettings => userSettings?.visitCount ?? 0),
                    switchMap((count: number) => userSettingsService.updateSettings$({ visitCount: count + 1 })),
                ),
                () => appState.visitCountHasUpdated(),
            ),
        );
    }
}
